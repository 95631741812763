import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './core/main/main.component';
import { AuthenticationGuardService } from './shared/authentication/authentication-guard.service';

/* tslint:disable:typedef */ /* tslint:disable:arrow-parens */
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuardService],
    component: MainComponent,
    children: [
      {
        path: 'operational-monitoring',
        loadChildren: () => import('src/app/operational-monitoring/operational-monitoring.module').then(m => m.OperationalMonitoringModule)
      },
      {
        path: 'performance-assessment',
        loadChildren: () => import('src/app/performance-assessment/performance-assessment.module').then(m => m.PerformanceAssessmentModule)
      },
      {
        path: 'airport',
        loadChildren: () => import('src/app/airport-details/airport-details.module').then(m => m.AirportDetailsModule)
      },
      {
        path: 'forecast',
        loadChildren: () => import('src/app/forecast/forecast.module').then(m => m.ForecastModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('src/app/notifications/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'atc',
        loadChildren: () => import('src/app/atc-view/atc-view.module').then(m => m.AtcViewModule)
      },
      {
        path: 'adsb',
        loadChildren: () => import('src/app/adsb-view/adsb-view.module').then(m => m.AdsbViewModule)
      },
      {
        path: 'edit-profile',
        loadChildren: () => import('src/app/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)
      },
      { path: '', redirectTo: 'performance-assessment', pathMatch: 'full' },
      { path: '**', redirectTo: 'performance-assessment' }
    ]
  }
];
/* tslint:enable:typedef*/ /* tslint:disable:arrow-parens */

/**
 * Application routing module.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
