import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { SLogService } from 'ngx-papyros';

import { AuthenticationService } from './authentication.service';

/**
 * Guard to check authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {

  constructor(private readonly router: Router, private readonly authenticationService: AuthenticationService,
              private readonly logger: SLogService) {
  }

  /**
   * Check if route can be activated.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  private checkLogin(url: string): boolean {
    const sessionActive: boolean = this.authenticationService.checkSession();
    if (!sessionActive) {
      this.router.navigate(['auth', 'login'])
        .catch((error: Error) => {
          this.logger.error(AuthenticationGuardService.name, 'Navigation error', error);
        });
    }

    this.authenticationService.setRedirectUrl(url);

    return sessionActive;
  }
}
