import { Injectable, TemplateRef } from '@angular/core';

import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';

import { EventTypeEnum, STATUS_BAR_EVENTS } from '../sse/event-type.enum';
import { SseErrorModel } from './model/sse-error.model';

/**
 * Sse Error Service
 */
@Injectable({
  providedIn: 'root'
})
export class SseErrorService {
  /**
   * General SSE Error subject.
   */
  generalErrorSubject: Subject<boolean> = new Subject();

  /**
   * SSE Error subject.
   */
  errorSubject: Subject<SseErrorModel> = new Subject();

  private readonly notifications: Map<EventTypeEnum, NzNotificationRef> = new Map<EventTypeEnum, NzNotificationRef>();

  constructor(private readonly notificationService: NzNotificationService) {
  }

  /**
   * Update visible notifications on route change
   */
  updateVisibleNotifications(page: string): void {
    const notificationsToKeep: EventTypeEnum[] = [EventTypeEnum.NOTIFICATIONS];

    if (page === 'operational-monitoring') {
      notificationsToKeep.push(...STATUS_BAR_EVENTS);
    }

    this.notifications.forEach((notification: NzNotificationRef, type: EventTypeEnum) => {
      if (notificationsToKeep.indexOf(type) === -1) {
        this.notificationService.remove(notification.messageId);
        this.notifications.delete(type);
      }
    });
  }

  /**
   * Init Subscribe to sse Errors
   */
  subscribeSseErrors(template: TemplateRef<any>): void {
    this.errorSubject.subscribe((sseError: SseErrorModel) => {
      const notification: NzNotificationRef = this.notifications.get(sseError.type);

      if (notification) {
        this.notificationService.remove(notification.messageId);
        this.notifications.delete(sseError.type);
      }

      if (sseError.hasError) {
        this.notifications.set(sseError.type,
          this.notificationService.template(template, { nzDuration: 0, nzData: sseError.type, nzClass: 'error-box' }
          )
        );
      }
    });
  }

  /**
   * Remove notifications by event type.
   */
  closeNotification(type: EventTypeEnum): void {
    const notification: NzNotificationRef | undefined = this.notifications.get(type);

    if (notification) {
      this.notificationService.remove(notification.messageId);
      this.notifications.delete(type);
    }
  }
}
