import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AuthenticationLoginComponent } from './login/authentication-login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationRecoverPasswordComponent } from './recover-password/authentication-recover-password.component';
import { AuthenticationSetPasswordComponent } from './set-password/authentication-set-password.component';

/**
 * Authentication Module
 */
@NgModule({
  declarations: [
    AuthenticationLoginComponent,
    AuthenticationRecoverPasswordComponent,
    AuthenticationSetPasswordComponent,
    AuthenticationComponent
  ],
  imports: [
    SharedModule,
    AuthenticationRoutingModule
  ]
})
export class AuthenticationModule {
}
