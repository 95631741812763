import { Component } from '@angular/core';

/**
 * Authentication container component
 */
@Component({
  selector: 'swr-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.less']
})
export class AuthenticationComponent {
}
