import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthenticationService } from './authentication.service';

/**
 * Directive to validate logged user permissions.
 */
@Directive({
  selector: '[swrUserHasPermission]'
})
export class UserHasPermissionDirective {

  private hasView: boolean = false;

  constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef,
              private readonly authenticationService: AuthenticationService) {
  }

  /**
   * Validate logged user permissions.
   */
  @Input()
  set swrUserHasPermission(permissions: string[]) {
    if (!permissions || permissions.length === 0) {
      throw new Error('User roles must be set has an array of string');
    }

    this.validatePermissions(permissions);
  }

  private validatePermissions(permissions: string[]): void {
    const userHasPermission: boolean = this.authenticationService.hasPermissions(permissions);
    if (userHasPermission && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!userHasPermission && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
