/**
 * Identify the error codes.
 */
export enum StatusCodeEnum {
  /**
   * No content.
   */
  NO_CONTENT = 204,

  /**
   * Unauthorized.
   */
  UNAUTHORIZED = 401,

  /**
   * Forbidden.
   */
  FORBIDDEN = 403,

  /**
   * Not found.
   */
  NOT_FOUND = 404,
}

/**
 * Session Expired Error Codes
 */
export const sessionExpiredErrorCodes: StatusCodeEnum[] = [
  StatusCodeEnum.UNAUTHORIZED,
  StatusCodeEnum.FORBIDDEN
];
