import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NamedModel } from '../model/named.model';
import { SseMessageModel } from '../sse/model/sse-message.model';
import { SseService } from '../sse/sse.service';
import { NotificationItemModel } from './model/notification-item.model';

/**
 * Notification alerts service.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationAlertsService implements OnDestroy {

  private template: TemplateRef<any>;
  private readonly alerts: Map<string, NzNotificationRef> = new Map<string, NzNotificationRef>();
  private readonly alertSubscription: Map<string, Subscription> = new Map<string, Subscription>();
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly notificationService: NzNotificationService, private readonly sseService: SseService,
              private readonly router: Router) {
  }

  /**
   * Handles destruction tasks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Init Subscribe to sse Errors
   */
  subscribeNotifications(template: TemplateRef<any>): void {
    this.template = template;
    this.subscriptions.push(this.sseService.notificationsSubject
      .pipe(filter(() => this.router.url.indexOf('notifications') === -1))
      .subscribe((message: SseMessageModel) => {
        this.handleNewNotification(JSON.parse(message.data));
      }));
  }

  /**
   * Handles new notifications.
   */
  handleNewNotification(notification: NotificationItemModel): void {
    if (!notification || !notification.discontinuity || !notification.discontinuity.airport || !this.template) {
      return;
    }

    const airport: NamedModel = notification.discontinuity.airport;

    if (!this.alerts.has(airport.id)) {
      const alert: NzNotificationRef = this.notificationService.template(this.template, {
        nzDuration: 0,
        nzData: airport,
        nzClass: 'primary-box'
      });

      this.alertSubscription.set(airport.id, alert.onClose.subscribe((closedByUser: boolean) => {
        this.closeNotification(airport.id);
      }));

      this.alerts.set(airport.id, alert);
    }
  }

  /**
   * Remove notifications by airport id.
   */
  private closeNotification(id: string): void {
    const notification: NzNotificationRef | undefined = this.alerts.get(id);

    if (notification) {
      this.notificationService.remove(notification.messageId);
      this.alerts.delete(id);
    }
  }
}
