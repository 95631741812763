import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { interval, merge, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { SLogService } from 'ngx-papyros';

import { environment } from '../../../environments/environment';
import { AirportService } from '../../shared/airport/airport.service';
import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { LocalizationService } from '../../shared/localization/localization.service';
import { NamedModel } from '../../shared/model/named.model';
import { UnreadNotificationByAirportModel } from '../../shared/notifications/model/unread-notification-by-airport.model';
import { UnreadNotificationModel } from '../../shared/notifications/model/unread-notification-model';
import { NotificationService } from '../../shared/notifications/notification.service';
import { SseErrorService } from '../../shared/sse-error/sse-error.service';
import { SseService } from '../../shared/sse/sse.service';

/**
 * Navigation Bar component.
 */
@Component({
  selector: 'swr-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit, OnDestroy {

  /**
   * Has general Sse Error
   */
  @Input() hasGeneralSseError: boolean;

  /**
   * The open menu event.
   */
  @Output() menuEvent: EventEmitter<boolean> = new EventEmitter();

  /**
   * The selected section key.
   */
  sectionKey: string;

  /**
   * The actual date.
   */
  today: Date;

  /**
   * If there are any unviewed notifications
   */
  hasNotifications: boolean;

  /**
   * The logged user name.
   */
  userName: string;

  /**
   * The unread notifications model.
   */
  unreadByAirport: UnreadNotificationByAirportModel[];

  /**
   * Is loading unread notifications.
   */
  isLoadingNotifications: boolean = true;

  /**
   * Has notification error.
   */
  hasNotificationError: boolean = false;

  private readonly subscriptions: Subscription[] = [];

  /**
   * Before unload must send the close event sink request.
   */
  @HostListener('window:beforeunload', [])
  beforeunloadHandler(): void {
    this.sseService.destroy();
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly router: Router, private readonly ngZone: NgZone,
              private readonly sseService: SseService, private readonly sseErrorService: SseErrorService,
              private readonly nzNotificationService: NzNotificationService, private readonly authenticationService: AuthenticationService,
              private readonly airportService: AirportService, private readonly notificationService: NotificationService,
              private readonly localizationService: LocalizationService, private readonly logger: SLogService) {
    this.today = new Date();
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.isLoadingNotifications = true;
    this.hasNotificationError = false;

    this.sseService.init();
    this.userName = this.authenticationService.getUserName();
    this.initDateUpdater();
    this.subscribeForRouterNavigation();
    this.subscribeForNotificationsSseEvents();
    this.subscribeForUserNameChange();
    this.updateTitle();
    this.updateUnreadNotifications();
  }

  /**
   * Handles destruction tasks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.sseService.destroy();
    this.nzNotificationService.remove();
  }

  /**
   * Opens the mobile menu.
   */
  openMenu(): void {
    this.menuEvent.emit(true);
  }

  /**
   * Logout the user.
   */
  logout(): void {
    this.airportService.clearAirports();
    this.sseService.destroy();
    this.authenticationService.logout()
      .then(() => this.handleLogout())
      .catch((error: HttpErrorResponse) => this.handleLogout(error));
  }

  private initDateUpdater(): void {
    this.ngZone.runOutsideAngular(() =>
      this.subscriptions.push(interval(10000).subscribe(() => this.ngZone.run(() => this.today = new Date()))));
  }

  private subscribeForRouterNavigation(): void {
    this.subscriptions.push(this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.updateTitle();
        this.sseErrorService.updateVisibleNotifications(this.router.url.split('/')[1]);
      }
    }));
  }

  private subscribeForNotificationsSseEvents(): void {
    this.subscriptions.push(
      merge(
        this.sseService.notificationsSubject.pipe(throttleTime(environment.unreadNotificationThrottle)),
        this.notificationService.updateNotificationsObservable
      )
        .subscribe(() => {
          this.updateUnreadNotifications();
        })
    );
  }

  private subscribeForUserNameChange(): void {
    this.subscriptions.push(this.authenticationService.sessionChanged
      .subscribe(() => {
        this.userName = this.authenticationService.getUserName();
      }));
  }

  private updateTitle(): void {
    const urlParts: string[] = this.router.url.split('/');
    let section: string = urlParts[1];

    if (section.indexOf('?') !== -1) {
      section = section.split('?')[0];
    }

    switch (section) {
      case 'notifications':
        this.sectionKey = 'notifications.title';
        break;
      case 'performance-assessment':
        this.sectionKey = 'performance-assessment.title';
        break;
      case 'operational-monitoring':
        this.sectionKey = 'operational-monitoring.title';
        break;
      case 'atc':
        this.sectionKey = 'operational-monitoring.atc-view.title';
        break;
      case 'adsb':
        this.sectionKey = 'adsb.title';
        break;
      case 'forecast':
        this.sectionKey = 'forecast.title';
        break;
      case 'airport':
        this.sectionKey = 'airport-details.title';
        break;
      case 'admin': {
        if (urlParts.length > 2) {
          let subSection: string = urlParts[2];

          if (subSection.indexOf('?') !== -1) {
            subSection = subSection.split('?')[0];
          }

          if (subSection === 'auditing') {
            this.sectionKey = 'admin.auditing.title';
          } else if (subSection === 'system-failures') {
            this.sectionKey = 'admin.system-failures.title';
          } else {
            this.sectionKey = null;
          }
        }
        break;
      }
      default:
        this.sectionKey = null;
        break;
    }
  }

  // HANDLERS
  private handleLogout(logoutError: HttpErrorResponse = null): void {
    this.router.navigate(['auth', 'login'])
      .catch((error: Error) => {
        this.logger.error(NavComponent.name, 'Navigation error', error);
      });

    if (logoutError) {
      this.logger.error(NavComponent.name, 'Logout error', logoutError);
    }
  }

  private updateUnreadNotifications(): void {
    this.notificationService.getUnread()
      .then((data: UnreadNotificationModel) => this.handleGetUnreadNotificationsSuccess(data))
      .catch((error: HttpErrorResponse) => this.handleGetUnreadNotificationsError(error));
  }

  private handleGetUnreadNotificationsSuccess(data: UnreadNotificationModel): void {
    this.hasNotifications = data.total > 0;
    this.unreadByAirport = data.unreadByAirport || [];
    this.hasNotificationError = false;
    this.isLoadingNotifications = false;
  }

  private handleGetUnreadNotificationsError(error: HttpErrorResponse): void {
    this.logger.error(NavComponent.name, 'Get Unread Notifications error', error);

    this.airportService.getAll()
      .then((airports: NamedModel[]) => {
        this.hasNotifications = false;
        this.hasNotificationError = true;

        this.unreadByAirport = airports.map((airport: NamedModel) => {
          return { ...airport, unread: undefined };
        });

        this.isLoadingNotifications = false;
      });

    this.nzNotificationService.error(
      this.localizationService.translate('nav.notifications.error.title'),
      this.localizationService.translate('nav.notifications.error.message'),
      { nzClass: 'error-box' }
    );
  }
}
