import { getTime, isAfter, isBefore, isEqual, isSameHour, sub } from 'date-fns';
import {
  Chart,
  ChartAnimationOptions,
  ChartElementsOptions,
  ChartHoverOptions,
  ChartLegendOptions,
  ChartPluginsOptions,
  ChartPoint,
  ChartScales,
  ChartTooltipOptions,
  TimeDisplayFormat,
  TimeUnit
} from 'chart.js';
import { Color } from 'ng2-charts';
import 'chartjs-plugin-downsample';

import { RgbaColor, RgbColor } from './color.utils';
import { ChartConfigModel } from '../model/chart-config.model';

/**
 * The number of events for a day chart.
 */
export const EVENTS_GROUP_SIZE_DAY: number = 15;

/**
 * The number of events for a hour chart.
 */
export const EVENTS_GROUP_SIZE_HOUR: number = 5;

/**
 * The histogram chart default config.
 */
export const HISTOGRAM_DEFAULT_CONFIG: ChartConfigModel = {
  decimalPlaces: 2,
  limit: 1000,
  classInterval: 0.5
};

/**
 * Generic chart configuration
 */
export const chartConfig: any = {
  colors: {
    orange: new RgbColor(255, 127, 14),
    blue: new RgbColor(31, 119, 180),
    red: new RgbColor(255, 33, 0),
    green: new RgbColor(97, 202, 97),
    yellow: new RgbColor(255, 206, 0),
    purple: new RgbColor(153, 51, 255),
    darkGrey: 'rgb(140, 140, 140)',
    transparent: 'rgba(0, 0, 0, 0)'
  }
};

type UnitType = '' | 'm' | '%';

/**
 * Default chart colors
 */
export const chartDefaultColors: Color[] = [
  getChartColor(new RgbColor(54, 162, 235)),
  getChartColor(new RgbColor(255, 206, 86)),
  getChartColor(new RgbColor(77, 83, 96)),
  getChartColor(new RgbColor(75, 192, 192)),
  getChartColor(new RgbColor(151, 187, 205)),
  getChartColor(new RgbColor(253, 180, 92)),
  getChartColor(new RgbColor(255, 99, 132)),
  getChartColor(new RgbColor(148, 159, 177)),
  getChartColor(new RgbColor(247, 70, 74)),
  getChartColor(new RgbColor(220, 220, 220)),
  getChartColor(new RgbColor(70, 191, 189)),
  getChartColor(new RgbColor(231, 233, 237))
];

/**
 * Default chart colors without points
 */
export const chartDefaultColorsWithoutPoints: Color[] = [
  getChartColor(new RgbColor(54, 162, 235), false),
  getChartColor(new RgbColor(255, 206, 86), false),
  getChartColor(new RgbColor(77, 83, 96), false),
  getChartColor(new RgbColor(75, 192, 192), false),
  getChartColor(new RgbColor(151, 187, 205), false),
  getChartColor(new RgbColor(253, 180, 92), false),
  getChartColor(new RgbColor(255, 99, 132), false),
  getChartColor(new RgbColor(148, 159, 177), false),
  getChartColor(new RgbColor(247, 70, 74), false),
  getChartColor(new RgbColor(220, 220, 220), false),
  getChartColor(new RgbColor(70, 191, 189), false),
  getChartColor(new RgbColor(231, 233, 237), false)
];

const AREA_CHARTS_TRANSPARENCY: number = 0.65;

/**
 * Chart colors for area charts
 */
export const chartColorsForAreaCharts: Color[] = [
  getChartColor(new RgbColor(54, 162, 235), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(255, 206, 86), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(77, 83, 96), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(75, 192, 192), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(151, 187, 205), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(253, 180, 92), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(255, 99, 132), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(148, 159, 177), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(247, 70, 74), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(220, 220, 220), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(70, 191, 189), false, AREA_CHARTS_TRANSPARENCY, false),
  getChartColor(new RgbColor(231, 233, 237), false, AREA_CHARTS_TRANSPARENCY, false)
];

/**
 * Default chart time display format
 */
export const timeDisplayFormats: TimeDisplayFormat = {
  second: 'HH:mm:ss',
  minute: 'HH:mm',
  hour: 'HH:mm',
  day: 'YYYY/MM/DD'
};

/**
 * Formats numbers as powers of 10 (scientific notation)
 */
export function formatAsPowersOf10(value: number): string | null {
  if (value === 0) {
    return '0';
  }

  const superscript: string[] = ['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹'];

  const valueString: string = value.toExponential();
  const strings: string[] = valueString.split('e', 2);
  let exp: string = '';

  if (strings.length === 2 && strings[0] === '1') {
    for (let i: number = 0; i < strings[1].length; i++) {
      if (strings[1][i] === '-') {
        exp += '⁻';
      } else {
        const expNumber: number = Number(strings[1].charAt(i));

        if (!Number.isNaN(expNumber)) {
          exp += superscript[expNumber];
        }
      }
    }

    return `10 ${exp}`;
  }

  return null;
}

/**
 * Formats numbers as thousands (1, 100, 10k, 20k)
 */
export function formatAsThousands(value: number): string | null {
  const num: number = Math.abs(value);

  if (num === 0) {
    return '0';
  }

  if (num.toFixed(0).charAt(0) !== '1') {
    return null;
  }

  if (num >= 1000) {
    return `${(Math.sign(value) * (num / 1000)).toFixed(0)}k`;
  } else {
    return `${Math.sign(value) * num}`;
  }
}

/**
 * Only return if number is integer
 */
export function onlyIntegers(value: number): number | null {
  if (Number.isInteger(value)) {
    return value;
  }

  return null;
}

/**
 * Get logarithmic max.
 * @param max The max value.
 */
export function getLogarithmicMax(max: number): number {
  const maxExp: string = max.toExponential();
  return Number(`1e${+maxExp.split('e', 2)[1] + 1}`);
}

/**
 * Get logarithmic min.
 * @param min The min value.
 */
export function getLogarithmicMin(min: number): number {
  if (min === 0) {
    return min;
  }

  const minExp: string = min.toExponential();
  const exponentialValues: string[] = minExp.split('e', 2);
  return Number(`1e${exponentialValues[0] === '1' ? +exponentialValues[1] - 1 : exponentialValues[1]}`);
}

/**
 * Create a chart.js color
 * @param rgbColor - the color to set
 * @param showPoints - show points (Default: true)
 * @param transparency - the transparency of the background color (Default: '55')
 * @param showBorder - show border (Default: true)
 */
export function getChartColor(rgbColor: RgbColor, showPoints: boolean = true, transparency: number = 0.33,
                              showBorder: boolean = true): any {
  const color: string = rgbColor.toString();

  return {
    backgroundColor: new RgbaColor(rgbColor, transparency).toString(),
    hoverBackgroundColor: color,
    borderColor: showBorder ? color : 'rgba(0, 0, 0, 0)',
    hoverBorderColor: color,
    pointBorderColor: showPoints ? color : 'rgba(0, 0, 0, 0)',
    pointBackgroundColor: showPoints ? color : 'rgba(0, 0, 0, 0)',
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color
  };
}

/**
 * Returns an array with the visible datasets index.
 */
export function getVisibleDataSetsIndex(chart: Chart): number[] {
  const shownIndex: number[] = [];

  for (let i: number = 0; i < chart.data.datasets.length; i++) {
    if (!chart.getDatasetMeta(i).hidden) {
      shownIndex.push(i);
    }
  }

  return shownIndex;
}

/**
 * Add Extra points to histogram data
 * @param chartData - the histogram data
 * @param difference - the difference to add / subtract
 */
export function addHistogramExtraPoints(chartData: ChartPoint[], difference: number): boolean {
  let startAtZero: boolean = false;

  if (chartData[0].y !== 0) {
    let minPoint: ChartPoint = { x: chartData[0].x as number - difference, y: 0 };

    if (minPoint.x < 0) {
      minPoint = { x: +chartData[0].x - 20, y: +chartData[0].y };
      startAtZero = true;
    }

    chartData.unshift(minPoint);
  }

  if (chartData[chartData.length - 1].y !== 0) {
    const maxPoint: ChartPoint = { x: +chartData[chartData.length - 1].x + difference, y: 0 };

    chartData.push(maxPoint);
  }

  return startAtZero;
}

/**
 * Get daily logarithmic scales.
 */
export function dailyLogarithmicScales(xLabel: string, yLabel: string, min: number, max: number): ChartScales {
  return {
    xAxes: [{
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: timeDisplayFormats
      },
      scaleLabel: {
        display: true,
        labelString: xLabel
      }
    }],
    yAxes: [{
      type: 'logarithmic',
      scaleLabel: {
        display: true,
        labelString: yLabel
      },
      ticks: {
        callback: formatAsPowersOf10,
        max: getLogarithmicMax(max),
        min: getLogarithmicMin(min)
      }
    }]
  };
}

/**
 * Get hourly logarithmic scales.
 */
export function hourlyLogarithmicScales(xLabel: string, yLabel: string, min: number, max: number): ChartScales {
  return {
    xAxes: [{
      type: 'time',
      time: {
        unit: 'hour',
        displayFormats: timeDisplayFormats
      },
      scaleLabel: {
        display: true,
        labelString: xLabel
      }
    }],
    yAxes: [{
      type: 'logarithmic',
      offset: true,
      scaleLabel: {
        display: true,
        labelString: yLabel
      },
      ticks: {
        callback: formatAsPowersOf10,
        max: getLogarithmicMax(max),
        min: getLogarithmicMin(min)
      }
    }]
  };
}

/**
 * Updates time series chart.
 */
export function updateAggregatedTimeSeriesChart(chart: Chart, seriesArray: Map<string, Map<Date, number>>,
                                                indexMap: Map<string, number>, timeUnit: TimeUnit,
                                                updateChart: boolean = true, dataKeyName: 'data' | 'originalData' = 'data'): void {
  seriesArray.forEach((series: Map<Date, number>, id: string) => {
    for (const [newPointDate, newPointValue] of series) {
      const newPoint: ChartPoint = {
        x: newPointDate,
        y: newPointValue
      };

      // TODO: replace [dataKeyName] with .data after the upgrade to chart.js v3
      const chartData: ChartPoint[] = chart.data.datasets[indexMap.get(id)][dataKeyName] as ChartPoint[];

      const firstPointDate: Date = chartData[0].x as Date;
      const lastPointDate: Date = chartData[chartData.length - 1].x as Date;

      if (isAfter(newPointDate, lastPointDate)) {
        let timeBefore: Date = lastPointDate;

        if (timeUnit === 'hour') {
          timeBefore = sub(newPointDate, { days: 1 });
        } else if (timeUnit === 'minute') {
          timeBefore = sub(newPointDate, { hours: 1 });
        }

        const pointIndex: number = chartData.findIndex((value: ChartPoint) => !isAfter(timeBefore, value.x as Date));

        if (pointIndex !== -1) {
          // Remove points up to the point index
          chartData.splice(0, pointIndex);
        }

        // Add new point at the end
        chartData.push(newPoint);
      } else if (isBefore(newPointDate, firstPointDate)) {
        // Is before the first point, add at start
        chartData.unshift(newPoint);
      } else {
        const pointIndex: number = chartData.findIndex((value: ChartPoint) => !isAfter(newPointDate, value.x as Date));

        if (pointIndex !== -1) {
          // Replace/Append point with new data
          chartData.splice(pointIndex, isEqual(chartData[pointIndex].x as Date, newPointDate) ? 1 : 0, newPoint);
        }
      }
    }
  });

  if (updateChart) {
    chart.update({ duration: 0 });
  }
}

/**
 * Get Max Y Value in Chart
 */
export function getMaxYValue(points: ChartPoint[], initial: number): number {
  return points.reduce((max: number, current: ChartPoint) => {
    if (current.y > max) {
      return current.y as number;
    }

    return max;
  }, initial);
}

/**
 * Get Min Y Value in Chart
 */
export function getMinYValue(points: ChartPoint[], initial: number): number {
  return points.reduce((min: number, current: ChartPoint) => {
    if (current.y < min) {
      return current.y as number;
    }

    return min;
  }, initial);
}

/**
 * DownSample Plugin Options
 */
export interface DownSamplePluginOptions {
  /**
   * Plugin enabled
   * default: false
   */
  enabled: boolean;

  /**
   * max number of points to display per dataset
   * default: 1000
   */
  threshold?: number;

  /**
   * if true, downsamples data automatically every update
   * default: true
   */
  auto?: boolean;

  /**
   * if true, downsamples data when the chart is initialized
   * default: true
   */
  onInit?: boolean;

  /**
   * if true, replaces the downsampled data with the original data after each update
   * default: true
   */
  restoreOriginalData?: boolean;

  /**
   * if true, downsamples original data instead of data
   * default: false
   */
  preferOriginalData?: boolean;

  /**
   * if not undefined and not empty, indicates the ids of the DataSets to DownSample
   * default: []
   */
  targetDatasets?: string[];
}

/**
 * Chart options.
 */
export class Options {
  /**
   * Responsive state.
   */
  responsive: boolean;

  /**
   * Maintain Aspect Ratio state.
   */
  maintainAspectRatio: boolean;

  /**
   * Responsive Animation Duration.
   */
  responsiveAnimationDuration: number;

  /**
   * Events to listen to.
   */
  events: string[];

  /**
   * Chart legend.
   */
  legend: ChartLegendOptions;

  /**
   * Chart elements.
   */
  elements: ChartElementsOptions;

  /**
   * Chart scales.
   */
  scales: ChartScales;

  /**
   * Chart tooltips.
   */
  tooltips: ChartTooltipOptions;

  /**
   * Chart hover.
   */
  hover: ChartHoverOptions;

  /**
   * Chart animation.
   */
  animation: ChartAnimationOptions;

  /**
   * Chart annotation.
   */
  annotation: any;

  /**
   * Chart plugins.
   */
  plugins: ChartPluginsOptions;

  /**
   * DownSample plugin options.
   */
  downsample: DownSamplePluginOptions;

  constructor(elements: ChartElementsOptions, scales: ChartScales, tooltips: ChartTooltipOptions,
              animation: ChartAnimationOptions | false, hover: ChartHoverOptions, chartAnnotations: any = [], plugins: ChartPluginsOptions,
              responsive: boolean = true, events: string[] = null, downsample: boolean = false) {
    this.responsiveAnimationDuration = 0;
    this.legend = { display: false };
    this.annotation = { annotations: chartAnnotations };

    if (responsive) {
      this.responsive = true;
      this.maintainAspectRatio = false;
    }

    if (elements) {
      this.elements = elements;
    }

    if (scales) {
      this.scales = scales;
    }

    if (tooltips) {
      this.tooltips = tooltips;
    }

    if (animation || animation === false) {
      this.animation = animation as ChartAnimationOptions;
    } else {
      this.animation = { duration: 0 };
    }

    if (hover) {
      this.hover = hover;
    }

    if (plugins) {
      this.plugins = plugins;
    }

    if (events) {
      this.events = events;
    }

    if (downsample) {
      this.downsample = { enabled: true, threshold: 10000, preferOriginalData: true, restoreOriginalData: false };
    }
  }
}

/**
 * Chart annotation.
 */
export class Annotation {
  /**
   * Annotation type.
   */
  type: string;

  /**
   * Annotation mode.
   */
  mode: string;

  /**
   * Annotation scale id.
   */
  scaleID: string;

  /**
   * Annotation value.
   */
  value: number;

  /**
   * Annotation border color.
   */
  borderColor: string;

  /**
   * Annotation border width.
   */
  borderWidth: number;

  /**
   * Annotation visibility.
   */
  visible: boolean;

  /**
   * Annotation label.
   */
  label: string;

  /**
   * Annotation unit.
   */
  unit: UnitType;

  constructor(mode: string, value: number, scaleId: string, name: string, unit: UnitType = 'm', formattedValue: string = null) {
    this.type = 'line';
    this.scaleID = scaleId;
    this.borderColor = chartConfig.colors.red.toString();
    this.borderWidth = 1;
    this.mode = mode;
    this.value = value;
    this.visible = false;
    this.label = `${name} (${formattedValue ?? value}${unit})`;
  }
}

/**
 * Percentile Chart annotation.
 */
export class PercentileAnnotation extends Annotation {
  constructor(mode: string, value: number, scaleId: string, name: string, unit: UnitType = 'm') {
    super(mode, value, scaleId, name, unit);
    this.borderColor = chartConfig.colors.purple.toString();
  }
}

const THRESHOLD_OFFSET_PERCENTAGE: number = 10;

const THRESHOLD_MAX_OFFSET: number = 2;

/**
 * Resolves the suggested value accordingly to the threshold offset value.
 */
export const resolveSuggestionMaxMin: (value: number, suggestionType: 'max' | 'min') => number =
  (value: number, suggestionType: 'max' | 'min'): number => {
    if (value === null || value === undefined) {
      return undefined;
    }
    const valueToCalculateThreshold: number = suggestionType === 'max' ? value || 1 : value;
    const thresholdValue: number = Math.min(valueToCalculateThreshold * (THRESHOLD_OFFSET_PERCENTAGE / 100), THRESHOLD_MAX_OFFSET);
    return value + (suggestionType === 'max' ? thresholdValue : -thresholdValue);
  };

/**
 * Returns the point position of some datetime and if it is to append or to create a new one.
 * @param data The char points data.
 * @param dateTime The date.
 * @param start The start index.
 * @param end The end index.
 */
export function resolveOutagePointIndex(data: ChartPoint[], dateTime: Date, start: number, end: number): [number, boolean] {
  if (start > end) {
    return [start, false];
  }

  const middle: number = Math.round((start + end) / 2);
  const value: ChartPoint = data[middle];
  const valueDate: Date = value.x as Date;

  if (isSameHour(valueDate, dateTime)) {
    return [middle, true];
  }

  const valueTimestamp: number = getTime(valueDate);
  const timeTimestamp: number = getTime(dateTime);

  if (timeTimestamp < valueTimestamp) {
    return resolveOutagePointIndex(data, dateTime, start, middle - 1);
  }

  if (timeTimestamp > valueTimestamp) {
    return resolveOutagePointIndex(data, dateTime, middle + 1, end);
  }

  return [middle, true];
}

/**
 * Gets the number of decimal places of a number.
 */
export function getNumberOfDecimalPlaces(value: number): number {
  const asString: string = `${value}`;
  const index: number = asString.indexOf('.');

  if (index !== -1) {
    return asString.length - index - 1;
  }

  return 0;
}
