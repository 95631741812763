import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { isEmpty } from 'lodash-es';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ErrorModel } from '../model/error.model';
import { ImageModel } from '../model/image.model';
import { NamedModel } from '../model/named.model';
import { StatusCodeEnum } from '../utils/status-code.enum';
import { StationAdsbModel } from '../model/station-adsb.model';
import { AirportDetailsRunwayModel } from './model/airport-details-runway.model';
import { AirportDetailsModel } from './model/airport-details.model';
import { StationDetailsModel } from './model/station-details.model';
import { ServiceType, StationFilterDataModel } from './model/station-filter-data.model';
import { StationModel } from '../model/station.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { StationReceiversModel } from './model/station-receivers.model';

const STORAGE_AIRPORT_KEY: string = 'airport';

/**
 * Airport service.
 */
@Injectable({
  providedIn: 'root'
})
export class AirportService {
  /**
   * Station Set Event
   */
  stationSetEvent: EventEmitter<StationModel> = new EventEmitter<StationModel>();

  /**
   * Service Set Event
   */
  serviceSetEvent: EventEmitter<ServiceType> = new EventEmitter<ServiceType>();

  private getAllSubscription: Subscription;
  private getServicesSubscription: Subscription;
  private getStationsSubscription: Subscription;
  private getStationsByServiceSubscription: Subscription;
  private getStationsForAdsbSubscription: Subscription;
  private getStationServicesSubscription: Subscription;
  private getStationReceiversSubscription: Subscription;
  private getStationsWithDetailsSubscription: Subscription;
  private getAirportDetailsSubscription: Subscription;
  private getAirportRunwaysSubscription: Subscription;
  private getAirportRunwayImageSubscription: Subscription;

  private airports: NamedModel[];
  private readonly storage: Storage = localStorage;

  constructor(private readonly httpClient: HttpClient, private readonly authenticationService: AuthenticationService) {
  }

  /**
   * Gets the selected airport from storage.
   */
  get selectedAirport(): string {
    const storedAirportId: string = this.storage.getItem(STORAGE_AIRPORT_KEY);

    if (storedAirportId) {
      return storedAirportId;
    }

    const currentAirport: string = this.authenticationService.getAirport() || this.airports[0]?.id;
    this.selectedAirport = currentAirport;
    return currentAirport;
  }

  /**
   * Sets the selected airport to storage.
   */
  set selectedAirport(id: string) {
    if (id) {
      this.storage.setItem(STORAGE_AIRPORT_KEY, id);
      return;
    }

    this.storage.removeItem(STORAGE_AIRPORT_KEY);
  }

  /**
   * Sets the selected station to storage.
   */
  set selectedStation(station: StationModel) {
    this.stationSetEvent.emit(station);
  }

  /**
   * Sets the selected station to storage.
   */
  set selectedService(service: ServiceType) {
    this.serviceSetEvent.emit(service);
  }

  /**
   * Gets airports list.
   */
  getAll(): Promise<NamedModel[]> {
    if (this.airports) {
      return Promise.resolve(this.airports);
    } else {
      if (this.getAllSubscription) {
        this.getAllSubscription.unsubscribe();
      }

      return new Promise(
        (resolve: (value?: NamedModel[]) => void, reject: (reason?: HttpErrorResponse) => void): void => {
          this.getAllSubscription = this.httpClient.get<NamedModel[]>(
            `${environment.apiPath}/airports`)
            .subscribe(
              (airports: NamedModel[]) => {
                this.airports = airports;
                resolve(this.airports);
              },
              (error: HttpErrorResponse) => reject(error)
            );
        });
    }
  }

  /**
   * Clear airports.
   */
  clearAirports(): void {
    this.airports = undefined;
  }

  /**
   * Gets airport services list.
   */
  getServices(airportId: string, period: string): Promise<ServiceType[]> {
    if (this.getServicesSubscription) {
      this.getServicesSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: ServiceType[]) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getServicesSubscription = this.httpClient.get<ServiceType[]>(
          `${environment.apiPath}/airports/${airportId}/services?period=${period}`)
          .subscribe(
            (services: ServiceType[]) => resolve(services),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Gets airport stations list.
   */
  getStations(airportId: string): Promise<StationModel[]> {
    if (this.getStationsSubscription) {
      this.getStationsSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: StationModel[]) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getStationsSubscription = this.httpClient.get<StationModel[]>(
          `${environment.apiPath}/airports/${airportId}/stations-all`)
          .subscribe(
            (stations: StationModel[]) => resolve(stations),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Gets airport stations list.
   */
  getStationsByService(airportId: string, service: ServiceType): Promise<StationModel[]> {
    if (this.getStationsByServiceSubscription) {
      this.getStationsByServiceSubscription.unsubscribe();
    }

    const httpParams: HttpParams = new HttpParams()
      .append('service', service);

    return new Promise(
      (resolve: (value?: StationModel[]) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getStationsByServiceSubscription = this.httpClient.get<StationModel[]>(
          `${environment.apiPath}/airports/${airportId}/stations-by-service`, { params: httpParams })
          .subscribe(
            (stations: StationModel[]) => resolve(stations),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Gets airport stations for adsb.
   */
  getStationsForAdsb(airportId: string): Promise<StationAdsbModel[]> {
    if (this.getStationsForAdsbSubscription) {
      this.getStationsForAdsbSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: StationAdsbModel[]) => void, reject: (reason?: ErrorModel) => void): void => {
        this.getStationsForAdsbSubscription = this.httpClient.get<StationAdsbModel[]>(
          `${environment.apiPath}/airports/${airportId}/stations-adsb`, { observe: 'response' })
          .subscribe((response: HttpResponse<StationAdsbModel[]>) => {
              try {
                if (response.status === StatusCodeEnum.NO_CONTENT || isEmpty(response.body)) {
                  reject({ type: 'EMPTY' });
                } else {
                  resolve(response.body);
                }
              } catch (error) {
                reject({ type: 'OTHER', content: error });
              }
            },
            (error: HttpErrorResponse) => reject({ type: 'HTTP', content: error })
          );
      });
  }

  /**
   * Gets station services list.
   */
  getStationServices(airportId: string, stationId: string): Promise<StationFilterDataModel> {
    if (this.getStationServicesSubscription) {
      this.getStationServicesSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: StationFilterDataModel) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getStationServicesSubscription = this.httpClient.get<StationFilterDataModel>(
          `${environment.apiPath}/airports/${airportId}/stations/${stationId}/services`)
          .subscribe(
            (servicesWithDates: StationFilterDataModel) => resolve(servicesWithDates),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Gets the station receivers.
   */
  getStationReceivers(stationId: string): Promise<StationReceiversModel> {
    if (this.getStationReceiversSubscription) {
      this.getStationReceiversSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: StationReceiversModel) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getStationReceiversSubscription = this.httpClient.get<StationReceiversModel>(
          `${environment.apiPath}/airports/stations/${stationId}/receivers`)
          .subscribe(
            (data: StationReceiversModel) => resolve(data),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Gets the list of stations of a single airport with details, by airport ID.
   */
  getStationsWithDetails(airportId: string): Promise<StationDetailsModel[]> {
    if (this.getStationsWithDetailsSubscription) {
      this.getStationsWithDetailsSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: StationDetailsModel[]) => void, reject: (reason?: ErrorModel) => void): void => {
        this.getStationsWithDetailsSubscription = this.httpClient.get<StationDetailsModel[]>(
          `${environment.apiPath}/airports/${airportId}/stations/details`, { observe: 'response' })
          .subscribe((response: HttpResponse<StationDetailsModel[]>) => {
              try {
                if (response.status === StatusCodeEnum.NO_CONTENT || isEmpty(response.body)) {
                  reject({ type: 'EMPTY' });
                } else {
                  resolve(response.body);
                }
              } catch (error) {
                reject({ type: 'OTHER', content: error });
              }
            },
            (error: HttpErrorResponse) => reject({ type: 'HTTP', content: error })
          );
      });
  }

  /**
   * Gets the airport details
   */
  getAirportDetails(airportId: string): Promise<AirportDetailsModel> {
    if (this.getAirportDetailsSubscription) {
      this.getAirportDetailsSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: AirportDetailsModel) => void, reject: (reason?: ErrorModel) => void): void => {
        this.getAirportDetailsSubscription = this.httpClient.get<AirportDetailsModel>(
          `${environment.apiPath}/airports/${airportId}/details`, { observe: 'response' })
          .subscribe((response: HttpResponse<AirportDetailsModel>) => {
              try {
                if (response.status === StatusCodeEnum.NO_CONTENT || isEmpty(response.body)) {
                  reject({ type: 'EMPTY' });
                } else {
                  resolve(response.body);
                }
              } catch (error) {
                reject({ type: 'OTHER', content: error });
              }
            },
            (error: HttpErrorResponse) => reject({ type: 'HTTP', content: error })
          );
      });
  }

  /**
   * Gets the airport details
   */
  getAirportRunways(airportId: string): Promise<AirportDetailsRunwayModel[]> {
    if (this.getAirportRunwaysSubscription) {
      this.getAirportRunwaysSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: AirportDetailsRunwayModel[]) => void, reject: (reason?: ErrorModel) => void): void => {
        this.getAirportRunwaysSubscription = this.httpClient.get<AirportDetailsRunwayModel[]>(
          `${environment.apiPath}/airports/${airportId}/runways/details`, { observe: 'response' })
          .subscribe((response: HttpResponse<AirportDetailsRunwayModel[]>) => {
              try {
                if (response.status === StatusCodeEnum.NO_CONTENT || isEmpty(response.body)) {
                  reject({ type: 'EMPTY' });
                } else {
                  resolve(response.body);
                }
              } catch (error) {
                reject({ type: 'OTHER', content: error });
              }
            },
            (error: HttpErrorResponse) => reject({ type: 'HTTP', content: error })
          );
      });
  }

  /**
   * Gets the airport runway image.
   *
   * @param airportId The selected airport id.
   * @param runwayId The selected runway.
   */
  getAirportRunwayImage(airportId: string, runwayId: string): Promise<ImageModel> {
    if (this.getAirportRunwayImageSubscription) {
      this.getAirportRunwayImageSubscription.unsubscribe();
    }

    return new Promise((resolve: (value?: ImageModel) => void, reject: (reason?: ErrorModel) => void): void => {
      this.getAirportRunwayImageSubscription = this.httpClient
        .get<ImageModel>(`${environment.apiPath}/airports/${airportId}/runways/image/${runwayId}`, { observe: 'response' })
        .subscribe((response: HttpResponse<ImageModel>) => {
            if (response.status === StatusCodeEnum.NO_CONTENT || isEmpty(response.body)) {
              reject({ type: 'EMPTY' });
            } else {
              resolve(response.body);
            }
          },
          (error: HttpErrorResponse) => reject({ type: 'HTTP', content: error })
        );
    });
  }
}
