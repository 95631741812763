/**
 * Session model.
 */
export interface SessionModel {
  /**
   * The user id.
   */
  id: string;

  /**
   * The jwt token.
   */
  jwtToken: string;

  /**
   * The user permissions.
   */
  permissions: string[];

  /**
   * The user name.
   */
  name: string;

  /**
   * The user's favorite airport id.
   */
  defaultAirportId: string;

  /**
   * The user's locale.
   */
  locale: string | null;
}

/**
 * Creates an empty session.
 */
export const session: (sessionId: string, sessionName: string, sessionAirport: string,
                       sessionToken: string, sessionPermissions: string[], sessionLocale: string | null) =>
  SessionModel = (sessionId: string, sessionName: string, sessionAirport: string,
                  sessionToken: string, sessionPermissions: string[], sessionLocale: string | null): SessionModel => {
  return {
    id: sessionId,
    jwtToken: sessionToken,
    permissions: sessionPermissions,
    name: sessionName,
    defaultAirportId: sessionAirport,
    locale: sessionLocale
  };
};
