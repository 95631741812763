import { Location } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { SLogService } from 'ngx-papyros';

import { AirportService } from './shared/airport/airport.service';
import { AuthenticationService } from './shared/authentication/authentication.service';
import { HtmlTitleService } from './shared/html-title-resolver/html-title.service';
import { LocalizationService } from './shared/localization/localization.service';
import { NamedModel } from './shared/model/named.model';
import { NotificationAlertsService } from './shared/notifications/notification-alerts.service';
import { SseErrorService } from './shared/sse-error/sse-error.service';
import { EventTypeEnum } from './shared/sse/event-type.enum';
import { SseService } from './shared/sse/sse.service';
import { PageInfoModel } from './shared/html-title-resolver/model/page-info-model';
import { environment } from '../environments/environment';

declare let gtag: (command: 'config', targetId: string, config?: any) => void;

/**
 * Application component.
 */
@Component({
  selector: 'swr-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  /**
   * The loading state.
   */
  isLoading: boolean;

  /**
   * Reload sse event template.
   */
  @ViewChild('reloadSseEventTemplate', { static: true }) reloadSseEventTemplate: TemplateRef<any>;

  /**
   * Airport notification template.
   */
  @ViewChild('notificationAlertTemplate', { static: true }) notificationAlertTemplate: TemplateRef<any>;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly localization: LocalizationService,
              private readonly router: Router, private readonly sseService: SseService, private readonly location: Location,
              private readonly sseErrorService: SseErrorService, private readonly alertsService: NotificationAlertsService,
              private readonly airportService: AirportService, private readonly authenticationService: AuthenticationService,
              private readonly logger: SLogService, private readonly htmlTitleService: HtmlTitleService) {
    this.isLoading = true;

    if (environment.gtag) {
      this.htmlTitleService.titleSubject.subscribe((page: PageInfoModel) => {
        gtag('config', environment.gtag.targetId,
          {
            // Pageview parameters
            page_title: page.title,
            page_path: page.path,
            // screen_view parameters
            app_name: environment.gtag.appName,
            app_version: environment.gtag.appVersion,
            screen_name: page.title,
            // IP anonymization
            anonymize_ip: true
          }
        );
      });
    }
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.logger.debug(AppComponent.name, 'Environment config', null, environment);
    this.sseErrorService.subscribeSseErrors(this.reloadSseEventTemplate);
    this.alertsService.subscribeNotifications(this.notificationAlertTemplate);

    this.localization.init();

    this.checkSession()
      .then(() => {
        this.htmlTitleService.init();
        this.isLoading = false;
      })
      .catch((error: Error) => this.logger.error(AppComponent.name, 'App initialization error', error));
  }

  /**
   * Reload sse event and close notification
   */
  reloadSseEvent(notification: any, event: EventTypeEnum): void {
    this.sseService.operationalMonitoringSectionReloadSubject.next(event);
    notification.close();
  }

  /**
   * Show or hide the reload button
   */
  showReload(error: EventTypeEnum): boolean {
    return error !== EventTypeEnum.NOTIFICATIONS;
  }

  /**
   * Navigate to airport notification page.
   */
  navigateToNotifications(notification: any, data: NamedModel): void {
    notification.close();
    this.router
      .navigate(['/notifications'], {
        queryParams: { airport: data.id }
      })
      .catch((error: Error) => {
        this.logger.error(AppComponent.name, 'Navigation error', error);
      });
  }

  private checkSession(): Promise<void> {
    return new Promise((resolve: (value?: void) => void, reject: (reason?: Error) => void): void => {
      if (this.authenticationService.checkSession()) {
        const locale: string | null = this.authenticationService.getLocale();

        Promise.all([this.localization.tryToSwitchLocale(locale), this.airportService.getAll()])
          .then(() => resolve())
          .catch((error: Error) => {
            this.logger.error(AppComponent.name, 'Get all airports error', error);
            resolve();
          });
      } else {
        this.localization.tryToSwitchLocale()
          .then(() => {
            if (this.location.path().indexOf('auth') > -1) {
              resolve();
            } else {
              this.router.navigate(['auth', 'login'])
                .then(() => {
                  resolve();
                })
                .catch((error: Error) => {
                  this.logger.error(AppComponent.name, 'Navigation error', error);
                  reject(error);
                });
            }
          })
          .catch((error: Error) => {
            this.logger.error(AppComponent.name, 'Switch to default locale error', error);
            reject(error);
          });
      }
    });
  }
}
