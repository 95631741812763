import { name, version } from '../../package.json';

/**
 * Development environment settings.
 */
export const environment: any = {
  production: false,
  apiPath: 'https://dev.swair.ptech.io/api/r',
  adsb: {
    defaultHeight: 200000,
    trailTime: 300,
    idleTime: 60,
    updateTimeout: 60,
    updateInterval: 5,
    movingAverageSize: 10,
    cartesianAxisMinDistance: 100
  },
  unreadNotificationThrottle: 30000,
  stanfordMaxValue: 100,
  gtag: {
    appName: name,
    appVersion: version,
    targetId: 'G-FRQMW54YZ2'
  },
  loggerConfig: {
    papyrosConfiguration: {
      baseUrl: 'https://dev.swair.ptech.io/papyros/',
      applicationVersion: version,
      applicationId: name,
    },
    sLogConfiguration: {
      callExternal: true,
      level: 'INFO'
    }
  },
  sse: {
    generalErrorDelay: 5_000, // milliseconds
    heartbeatTimeout: 120_000 // milliseconds
  }
};
