/**
 * Identify the sse events names.
 */
export enum EventTypeEnum {
  /**
   * Notification events.
   */
  NOTIFICATIONS = 'NOTIFICATIONS',

  /**
   * Status bar events.
   */
  OPERATIONAL_STATUS_BAR = 'OPERATIONAL_STATUS_BAR',

  /**
   * Accuracy instant events.
   */
  OPERATIONAL_ACCURACY_INSTANT = 'OPERATIONAL_ACCURACY_INSTANT',

  /**
   * Accuracy 95 events.
   */
  OPERATIONAL_ACCURACY_95 = 'OPERATIONAL_ACCURACY_95',

  /**
   * Accuracy 99,99 value events.
   */
  OPERATIONAL_ACCURACY_99_99 = 'OPERATIONAL_ACCURACY_99_99',

  /**
   * Integrity Protection Level instant events.
   */
  OPERATIONAL_INTEGRITY_PL_INSTANT = 'OPERATIONAL_INTEGRITY_PL_INSTANT',

  /**
   * Integrity Protection Level instant events.
   */
  OPERATIONAL_INTEGRITY_SI_INSTANT = 'OPERATIONAL_INTEGRITY_SI_INSTANT',

  /**
   * Integrity 99,99 value events.
   */
  OPERATIONAL_INTEGRITY_PL_99 = 'OPERATIONAL_INTEGRITY_PL_99',

  /**
   * Integrity stanford events.
   */
  OPERATIONAL_INTEGRITY_STANFORD = 'OPERATIONAL_INTEGRITY_STANFORD',

  /**
   * Availability service value events.
   */
  OPERATIONAL_AVAILABILITY_SERVICE = 'OPERATIONAL_AVAILABILITY_SERVICE',

  /**
   * Availability instantaneous PDOP events.
   */
  OPERATIONAL_AVAILABILITY_PDOP_INSTANT = 'OPERATIONAL_AVAILABILITY_PDOP_INSTANT',

  /**
   * Availability PDOP value events.
   */
  OPERATIONAL_AVAILABILITY_PDOP = 'OPERATIONAL_AVAILABILITY_PDOP',

  /**
   * Availability satellites value events.
   */
  OPERATIONAL_AVAILABILITY_SATELLITES_VALUES = 'OPERATIONAL_AVAILABILITY_SATELLITES_VALUES',

  /**
   * Availability satellites chart events.
   */
  OPERATIONAL_AVAILABILITY_SATELLITES_CHART = 'OPERATIONAL_AVAILABILITY_SATELLITES_CHART',

  /**
   * Continuity events.
   */
  OPERATIONAL_CONTINUITY = 'OPERATIONAL_CONTINUITY',

  /**
   * Space Weather summary events.
   */
  OPERATIONAL_SPACE_WEATHER_SUMMARY = 'OPERATIONAL_SPACE_WEATHER_SUMMARY',

  /**
   * Space Weather tec overview events.
   */
  OPERATIONAL_SPACE_WEATHER_OVERVIEW_TEC = 'OPERATIONAL_SPACE_WEATHER_OVERVIEW_TEC',

  /**
   * Space Weather protons overview events.
   */
  OPERATIONAL_SPACE_WEATHER_OVERVIEW_PROTONS = 'OPERATIONAL_SPACE_WEATHER_OVERVIEW_PROTONS',

  /**
   * Space Weather x-rays overview events.
   */
  OPERATIONAL_SPACE_WEATHER_OVERVIEW_XRAYS = 'OPERATIONAL_SPACE_WEATHER_OVERVIEW_XRAYS',

  /**
   * Space Weather geomagnetic overview events.
   */
  OPERATIONAL_SPACE_WEATHER_OVERVIEW_GEOMAGNETIC = 'OPERATIONAL_SPACE_WEATHER_OVERVIEW_GEOMAGNETIC',

  /**
   * Atc View events.
   */
  OPERATIONAL_ATC = 'OPERATIONAL_ATC',

  /**
   * Adsb View events.
   */
  OPERATIONAL_ADSB = 'OPERATIONAL_ADSB'
}

/**
 * Returns an array of all sse operational monitoring event types.
 */
export const sseOperationalMonitoringTypes: () => EventTypeEnum[] = (): EventTypeEnum[] => {
  return [
    EventTypeEnum.OPERATIONAL_ACCURACY_INSTANT,
    EventTypeEnum.OPERATIONAL_ACCURACY_95,
    EventTypeEnum.OPERATIONAL_ACCURACY_99_99,
    EventTypeEnum.OPERATIONAL_INTEGRITY_PL_INSTANT,
    EventTypeEnum.OPERATIONAL_INTEGRITY_SI_INSTANT,
    EventTypeEnum.OPERATIONAL_INTEGRITY_PL_99,
    EventTypeEnum.OPERATIONAL_INTEGRITY_STANFORD,
    EventTypeEnum.OPERATIONAL_AVAILABILITY_SERVICE,
    EventTypeEnum.OPERATIONAL_AVAILABILITY_PDOP_INSTANT,
    EventTypeEnum.OPERATIONAL_AVAILABILITY_PDOP,
    EventTypeEnum.OPERATIONAL_AVAILABILITY_SATELLITES_VALUES,
    EventTypeEnum.OPERATIONAL_AVAILABILITY_SATELLITES_CHART,
    EventTypeEnum.OPERATIONAL_CONTINUITY,
    EventTypeEnum.OPERATIONAL_SPACE_WEATHER_SUMMARY,
    EventTypeEnum.OPERATIONAL_SPACE_WEATHER_OVERVIEW_TEC,
    EventTypeEnum.OPERATIONAL_SPACE_WEATHER_OVERVIEW_PROTONS,
    EventTypeEnum.OPERATIONAL_SPACE_WEATHER_OVERVIEW_XRAYS,
    EventTypeEnum.OPERATIONAL_SPACE_WEATHER_OVERVIEW_GEOMAGNETIC,
    EventTypeEnum.OPERATIONAL_ATC,
    EventTypeEnum.OPERATIONAL_ADSB
  ];
};

/**
 * Status bar events.
 */
export const STATUS_BAR_EVENTS: EventTypeEnum[] = [
  EventTypeEnum.OPERATIONAL_STATUS_BAR
];
