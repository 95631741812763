<router-outlet *ngIf="!isLoading else loading"></router-outlet>

<ng-template #loading>
  <div class="absolute-middle large">
    <nz-spin nzSimple nzSize="large"></nz-spin>
  </div>
</ng-template>

<ng-template #reloadSseEventTemplate let-error="data" let-notification>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <em class="text-error" nz-icon nzTheme="outline" nzType="close-circle"></em>
      </span>
      <div class="ant-notification-notice-message">{{ 'sse.error.' + error | translate:locale.language }}</div>
      <span *ngIf="showReload(error)" class="ant-notification-notice-btn">
        <button (click)="notification.close()" class="close-btn" nz-button nzSize="default" nzType="link">
          {{ 'alerts.close' | translate:locale.language }}
        </button>
        <button (click)="reloadSseEvent(notification, error)" nz-button nzSize="default" nzType="link">
          {{ 'alerts.reload' | translate:locale.language }}
        </button>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #notificationAlertTemplate let-notification let-data="data">
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <em nz-icon nzTheme="outline" nzType="info-circle"></em>
      </span>
      <div class="ant-notification-notice-message">{{ 'notifications.alert.title' | translate:locale.language:{name: data.name} }}</div>
      <div class="ant-notification-notice-description">{{ 'notifications.alert.description' | translate:locale.language }}</div>
      <span class="ant-notification-notice-btn">
        <button (click)="notification.close()" class="close-btn" nz-button nzSize="default" nzType="link">
          {{ 'alerts.close' | translate:locale.language }}
        </button>
        <button (click)="navigateToNotifications(notification, data)" nz-button nzSize="default" nzType="link">
          {{ 'notifications.alert.view' | translate:locale.language }}
        </button>
      </span>
    </div>
  </div>
</ng-template>
