/**
 * The joiner string between each title.
 */
export const TITLES_JOINER: string = ' - ';

/**
 * The joiner string between titles and suffix.
 */
export const SUFFIX_JOINER: string = ' | ';

const PATH_TRANSLATION_KEY_PREFIX: string = 'html-title';
const PATH_TRANSLATION_KEY_SUFFIX: string = 'label';
const PATH_TRANSLATION_DEFAULT_KEY: string = `${PATH_TRANSLATION_KEY_PREFIX}.default`;
const PATH_TRANSLATION_SUFFIX_KEY: string = `${PATH_TRANSLATION_KEY_PREFIX}.suffix`;

/**
 * Builds the path translation key.
 * @param value The value to include on the key.
 */
export function createPathTranslationKey(value: string): string {
  return `${PATH_TRANSLATION_KEY_PREFIX}.${value}.${PATH_TRANSLATION_KEY_SUFFIX}`;
}

/**
 * Returns the default key.
 */
export function createDefaultKey(): string {
  return PATH_TRANSLATION_DEFAULT_KEY;
}

/**
 * Returns the suffix key.
 */
export function createSuffixKey(): string {
  return PATH_TRANSLATION_SUFFIX_KEY;
}
