import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { EventSourcePolyfill } from 'event-source-polyfill';
import { Subject } from 'rxjs';
import { SLogService } from 'ngx-papyros';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../../environments/environment';
import { SseErrorService } from '../sse-error/sse-error.service';
import { EventTypeEnum, sseOperationalMonitoringTypes, STATUS_BAR_EVENTS } from './event-type.enum';
import { SseMessageModel } from './model/sse-message.model';
import { SseParamsModel } from './model/sse-params.model';

// Server Sent Events constants
const STORAGE_TOKEN_KEY: string = 'token';
const STORAGE_SESSION_SSE_KEY: string = 'process_identifier';
const PROCESS_IDENTIFIER_HEADER: string = 'Swair-Process-Identifier';

/**
 * Service to manage sse events.
 */
@Injectable({
  providedIn: 'root'
})
export class SseService {

  /**
   * Notification subject.
   */
  notificationsSubject: Subject<SseMessageModel> = new Subject();

  /**
   * Status bar subject.
   */
  operationalMonitoringStatusBarSubject: Subject<SseMessageModel> = new Subject();

  /**
   * Space weather summary subject.
   */
  operationalMonitoringSpaceWeatherSummarySubject: Subject<SseMessageModel> = new Subject();

  /**
   * Instantaneous PL subject.
   */
  operationalMonitoringIntegrityInstantaneousPLSubject: Subject<SseMessageModel> = new Subject();

  /**
   * Operational monitoring section register subject.
   */
  operationalMonitoringSectionRegisterSubject: Subject<EventTypeEnum> = new Subject();

  /**
   * Operational monitoring subjects.
   */
  operationalMonitoringSubjectsMap: Map<EventTypeEnum, Subject<SseMessageModel>> = new Map<EventTypeEnum, Subject<SseMessageModel>>();

  /**
   * Operational monitoring section reload subject.
   */
  operationalMonitoringSectionReloadSubject: Subject<EventTypeEnum> = new Subject();

  /**
   * Get the sse error status.
   */
  get hasSseError(): boolean {
    return this.hasError;
  }

  private isFirstStart: boolean = false;
  private source: EventSourcePolyfill;
  private pendingEvents: SseParamsModel[] = [];
  private hasError: boolean = false;
  private readonly sessionUUID: string = null;
  private readonly storageLocal: Storage = localStorage;
  private readonly storageSession: Storage = sessionStorage;
  private readonly activeEventsMap: Map<EventTypeEnum, SseParamsModel> = new Map<EventTypeEnum, SseParamsModel>();

  constructor(private readonly httpClient: HttpClient, private readonly ngZone: NgZone,
              private readonly sseErrorManager: SseErrorService, private readonly logger: SLogService) {
    this.sessionUUID = this.generateUUID();
    sseOperationalMonitoringTypes().forEach((type: EventTypeEnum) =>
      this.operationalMonitoringSubjectsMap.set(type, new Subject()));
  }

  /**
   * Initiates SSE service.
   */
  init(): void {
    this.isFirstStart = true;

    // Finish a possible existing connection. Required in firefox.
    this.finishConnection()
      .then(() => this.startConnection())
      .catch((error: HttpErrorResponse) => {
        this.startConnection();
        this.logger.error(SseService.name, 'Finish Connection error', error);
      });
  }

  /**
   * Destroys the SSE service data.
   */
  destroy(): void {
    if (this.source) {
      this.source.close();
      this.activeEventsMap.clear();
      this.pendingEvents = [];
      this.finishConnection()
        .catch((error: HttpErrorResponse) => {
          this.logger.error(SseService.name, 'Finish Connection error', error);
        });
    }
  }

  /**
   * Registers an event.
   */
  registerEvent(type: EventTypeEnum, airport: string = null, service: string = null, period: string = null, stations: string = null): void {
    if (this.source && this.source.readyState === 1) {
      const httpHeaders: HttpHeaders = this.appendSseSessionHeader(new HttpHeaders());
      let httpParams: HttpParams = new HttpParams().append('type', type);

      httpParams = airport ? httpParams.append('airportId', airport) : httpParams;
      httpParams = service ? httpParams.append('service', service) : httpParams;
      httpParams = period ? httpParams.append('period', period) : httpParams;

      if (stations) {
        for (const station of stations.split(';')) {
          httpParams = httpParams.append('stationId', station);
        }
      }

      this.httpClient.post<void>(`${environment.apiPath}/sse/events`, null, { params: httpParams, headers: httpHeaders })
        .subscribe(
          () => {
            this.activeEventsMap.set(type, { type, airport, service, period, stations });
            this.addListener(type);
            this.propagateRegister(type, false);
          },
          (error: HttpErrorResponse) => {
            this.propagateRegister(type, true);
            this.logger.error(SseService.name, 'Register Event error', error);
          }
        );
    } else {
      this.pendingEvents.push({ type, airport, service, period, stations });
    }
  }

  /**
   * Unregisters an event.
   */
  unregisterEvent(type: EventTypeEnum): Promise<void> {
    if (this.source && this.source.readyState === 1) {
      this.removeListener(type);
      this.removeEventFromActive(type);

      const httpParams: HttpParams = new HttpParams().append('type', type);
      const httpHeaders: HttpHeaders = this.appendSseSessionHeader(new HttpHeaders());

      return new Promise((resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.httpClient.delete(`${environment.apiPath}/sse/events`, { params: httpParams, headers: httpHeaders })
          .subscribe(
            () => resolve(),
            (error: HttpErrorResponse) => reject(error)
          );
      });
    } else {
      this.removeEventFromPending(type);
      return Promise.resolve();
    }
  }

  private registerPendingEvents(): void {
    this.isFirstStart = false;
    this.registerEvent(EventTypeEnum.NOTIFICATIONS);

    this.pendingEvents.forEach((eventParams: SseParamsModel) => {
      this.registerEvent(eventParams.type, eventParams.airport, eventParams.service, eventParams.period, eventParams.stations);
    });

    this.pendingEvents = [];
  }

  private registerActiveEvents(): void {
    const unregisterPromises: Promise<void>[] = [];
    const eventsMap: Map<EventTypeEnum, SseParamsModel> = new Map<EventTypeEnum, SseParamsModel>();

    Array.from(this.activeEventsMap.keys()).forEach((eventType: EventTypeEnum) => {
      eventsMap.set(eventType, this.activeEventsMap.get(eventType));
      unregisterPromises.push(this.unregisterEvent(eventType));
    });

    Promise.all(unregisterPromises)
      .then(() => {
        Array.from(eventsMap.keys()).forEach((eventType: EventTypeEnum) => {
          const sseParams: SseParamsModel = eventsMap.get(eventType);
          this.registerEvent(sseParams.type, sseParams.airport, sseParams.service, sseParams.period);
        });
      });
  }

  private removeEventFromActive(type: EventTypeEnum): void {
    if (this.activeEventsMap.get(type)) {
      this.activeEventsMap.delete(type);
    }
  }

  private removeEventFromPending(type: EventTypeEnum): void {
    this.pendingEvents = this.pendingEvents.filter((event: SseParamsModel) => event.type !== type);
  }

  private startConnection(): void {
    this.ngZone.runOutsideAngular(() => {
      this.source = new EventSourcePolyfill(
        `${environment.apiPath}/sse`,
        {
          headers: {
            Authorization: `Bearer ${this.storageLocal.getItem(STORAGE_TOKEN_KEY)}`,
            'Content-Type': 'text/event-stream',
            [PROCESS_IDENTIFIER_HEADER]: this.sessionUUID
          },
          heartbeatTimeout: environment.sse.heartbeatTimeout,
          withCredentials: false
        }
      );

      this.source.onopen = (): void => {
        this.hasError = false;
        this.ngZone.run(() => this.sseErrorManager.generalErrorSubject.next(false));
        this.isFirstStart ? this.registerPendingEvents() : this.registerActiveEvents();
      };

      this.source.onerror = (error: MessageEvent): void => this.handleEventSourceErrorEvent(error);
    });
  }

  private finishConnection(): Promise<void> {
    return new Promise((resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
      const httpHeaders: HttpHeaders = this.appendSseSessionHeader(new HttpHeaders());
      this.httpClient.delete<void>(`${environment.apiPath}/sse`, { headers: httpHeaders })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  private addListener(type: EventTypeEnum): void {
    this.source.addEventListener(type, (message: SseMessageModel) => {
      this.logger.debug(SseService.name, `${type} event received`, null, JSON.parse(message.data));

      this.ngZone.run(() => {
        switch (message.type) {
          case EventTypeEnum.NOTIFICATIONS:
            this.notificationsSubject.next(message);
            break;
          case EventTypeEnum.OPERATIONAL_STATUS_BAR:
            this.operationalMonitoringStatusBarSubject.next(message);
            break;
          case EventTypeEnum.OPERATIONAL_INTEGRITY_PL_INSTANT:
            this.operationalMonitoringIntegrityInstantaneousPLSubject.next(message);
            break;
          case EventTypeEnum.OPERATIONAL_SPACE_WEATHER_SUMMARY:
            this.operationalMonitoringSpaceWeatherSummarySubject.next(message);
            break;
          default:
            this.operationalMonitoringSubjectsMap.get(type).next(message);
            break;
        }
      });
    });
  }

  private removeListener(type: EventTypeEnum): void {
    if (this.source) {
      this.source._listeners[type] = []; // TODO this should be done with removeEventListener...
    }
  }

  private propagateRegister(type: EventTypeEnum, hasError: boolean): void {
    this.ngZone.run(() => {
      this.sseErrorManager.errorSubject.next({ type, hasError });

      if (type !== EventTypeEnum.NOTIFICATIONS && STATUS_BAR_EVENTS.indexOf(type) === -1) {
        this.operationalMonitoringSectionRegisterSubject.next(type);
      }
    });
  }

  private handleEventSourceErrorEvent(error: MessageEvent): void {
    this.ngZone.run(() => {
      this.hasError = true;
      this.sseErrorManager.generalErrorSubject.next(true);
    });

    this.logger.error(SseService.name, 'SSE Source error', error as any);
    error.preventDefault();
  }

  private appendSseSessionHeader(headers: HttpHeaders): HttpHeaders {
    return headers.append(PROCESS_IDENTIFIER_HEADER, this.sessionUUID);
  }

  private generateUUID(): string {
    let uuid: string = this.storageSession.getItem(STORAGE_SESSION_SSE_KEY);
    if (!uuid) {
      uuid = uuidv4();
      this.storageSession.setItem(STORAGE_SESSION_SSE_KEY, uuid);
    }
    return uuid;
  }
}
