import { Injectable } from '@angular/core';

import * as moment from 'moment-timezone';
import { ChartOptions } from 'chart.js';
import { defaultsDeep } from 'lodash-es';
import { L10nIntlService, L10nLoader, L10nTranslationService } from 'angular-l10n';
import { NzI18nService } from 'ng-zorro-antd/i18n';

import { availableLocales, defaultLocale } from './localization.config';
import { LocaleModel } from './locale.model';
import { getNumberOfDecimalPlaces, Options } from '../utils/chart.util';

/**
 * Service to handle the localization.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private currentLocale: LocaleModel;

  constructor(private readonly translation: L10nTranslationService, private readonly nzI18n: NzI18nService,
              private readonly intlService: L10nIntlService) {
  }

  /**
   * Initializes the libs.
   */
  init(): void {
    moment.tz.setDefault('Etc/UTC');
  }

  /**
   * Try to switch to locale.
   */
  async tryToSwitchLocale(sessionLocale?: string | null): Promise<void> {
    if (!sessionLocale) {
      return await this.switchLocale(this.getDefaultLocale());
    }

    return await this.switchLocale(
      availableLocales.find((locale: LocaleModel) => locale.id === sessionLocale) ?? this.getDefaultLocale()
    );
  }

  /**
   * Switch the current locale.
   */
  async switchLocale(locale: LocaleModel): Promise<void> {
    this.nzI18n.setLocale(locale.ngZorro);
    this.nzI18n.setDateLocale(locale.dateFns);
    moment.locale(locale.l10nLocale.language);
    await this.translation.setLocale(locale.l10nLocale);
    this.currentLocale = locale;
  }

  /**
   * Translates a key.
   * @param key The key to translate.
   * @param params The parameters to add to the translation string.
   * @return The translation.
   */
  translate(key: string, params: any = null): string {
    return this.translation.translate(key, params);
  }

  /**
   * Gets the chart options with localized Y axis.
   */
  getChartOptionsWithLocalization(currentOptions?: Options): Options {
    const defaultOptions: ChartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            callback: (value: number, index: number, values: number[]) => {
              if (value !== 0) {
                const decimalPlaces: number[] = values.map((v: number) => getNumberOfDecimalPlaces(v));
                return this.intlService.formatNumber(value, { minimumFractionDigits: Math.max.apply(Math, decimalPlaces) });
              } else {
                return this.intlService.formatNumber(value);
              }
            }
          }
        }]
      }
    };

    return defaultsDeep(currentOptions, defaultOptions);
  }

  /**
   * Returns the current locale.
   */
  getCurrentLocale(): LocaleModel {
    return this.currentLocale;
  }

  /**
   * Gets the default locale.
   */
  private getDefaultLocale(): LocaleModel {
    const languages: string[] = this.getBrowserLanguages();

    return availableLocales.find((locale: LocaleModel) =>
      languages.some((language: string) => locale.l10nLocale.language.startsWith(language))
    ) ?? defaultLocale;
  }

  /**
   * Gets the navigator languages.
   */
  private getBrowserLanguages(): string[] {
    const browserLocales: ReadonlyArray<string> = navigator.languages ?? [navigator.language] ?? [defaultLocale.l10nLocale.language];

    return browserLocales.map((locale: string) => locale.trim().split(/-|_/)[0]);
  }
}

/**
 * Initiates the localization.
 * @param l10nLoader The localization loader.
 */
export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return (): Promise<void> => l10nLoader.init();
}
