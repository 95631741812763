<form nz-form (ngSubmit)="onSubmitForm(username, password)" #loginForm="ngForm">
  <nz-alert *ngIf="errorKey" class="mb-md" nzType="error" nzMessage="{{ errorKey | translate:locale.language }}"></nz-alert>
  <!--USERNAME-->
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ user.username ? '': 'authentication.login.username.empty' | translate:locale.language }}"
                     [nzValidateStatus]="(loginForm.submitted && errorKey) || (!user.username && username.dirty) ? 'error' : ''">
      <nz-input-group [nzPrefix]="prefixUser">
        <input swrAutoFocus required nz-input type="text" name="username" [(ngModel)]="user.username" #username="ngModel"
               (keydown)="onChange()" placeholder="{{ 'authentication.login.username.label' | translate:locale.language }}" autocomplete="username"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <!--PASSWORD-->
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ user.password ? '': 'authentication.login.password.empty' | translate:locale.language }}"
                     [nzValidateStatus]="(loginForm.submitted && errorKey) || (!user.password && password.dirty) ? 'error' : ''">
      <nz-input-group [nzPrefix]="prefixKey">
        <input required nz-input type="password" name="password" [(ngModel)]="user.password" #password="ngModel" (keydown)="onChange()"
               placeholder="{{ 'authentication.login.password.label' | translate:locale.language }}" autocomplete="current-password"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <!--SUBMIT-->
  <nz-form-control>
    <button nz-button [nzBlock]="true" [nzType]="'primary'" [nzLoading]="isProcessingLogin" type="submit">
      {{ 'authentication.login.button' | translate:locale.language | uppercase }}
    </button>
  </nz-form-control>
</form>
<!--FORGOT-->
<div class="mt-md text-center">
  <a class="forgot-password-link" [routerLink]="['/auth/recover-password']">
    <em nz-icon nzType="lock"></em>
    {{ 'authentication.login.recover-password' | translate:locale.language }}
  </a>
</div>
<!--ABOUT-->
<a href="https://www.swair.ptech.io/" class="about-link">
  <em nz-icon nzType="rocket" nzTheme="outline"></em>
  <span>{{ 'authentication.login.about.label' | translate:locale.language }}</span>
</a>

<ng-template #prefixUser><em nz-icon nzType="user"></em></ng-template>
<ng-template #prefixKey><em nz-icon nzType="key"></em></ng-template>
