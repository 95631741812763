<form *ngIf="!successKey" nz-form (ngSubmit)="onSubmitForm(emailControl)" #recoverForm="ngForm">
  <nz-alert *ngIf="errorKey" class="mb-md" nzType="error" nzMessage="{{ errorKey | translate:locale.language }}"></nz-alert>

  <!--EMAIL-->
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errorEmail"
                     [nzValidateStatus]="(recoverForm.submitted && errorKey) || (emailControl.errors && emailControl.dirty) ? 'error' : ''">
      <nz-input-group [nzPrefix]="prefixUser">
        <input swrAutoFocus required nz-input type="email" name="email" [(ngModel)]="email" #emailControl="ngModel"
               (keydown)="onChange()" placeholder="{{ 'authentication.recover-password.email.label' | translate:locale.language }}"
               autocomplete="true" email="true"/>
        <ng-template #errorEmail>
          <ng-container *ngIf="emailControl.hasError('required')">
            {{'authentication.recover-password.email.empty' | translate:locale.language}}
          </ng-container>
          <ng-container *ngIf="!emailControl.hasError('required') && emailControl.hasError('email')">
            {{'authentication.recover-password.email.invalid' | translate:locale.language}}
          </ng-container>
        </ng-template>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <!--SUBMIT-->
  <nz-form-control>
    <button nz-button [nzBlock]="true" [nzType]="'primary'" [nzLoading]="isProcessing" type="submit">
      {{ 'authentication.recover-password.button' | translate:locale.language | uppercase }}
    </button>
  </nz-form-control>
</form>

<!--LOGIN-->
<div *ngIf="!successKey" class="mt-md text-center">
  <a class="forgot-password-link" [routerLink]="['/auth/login']">
    <em nz-icon nzType="lock"></em>
    {{ 'authentication.recover-password.login' | translate:locale.language }}
  </a>
</div>

<div *ngIf="successKey">
  <nz-alert *ngIf="successKey" class="mb-lg" nzType="success" nzMessage="{{ successKey | translate:locale.language }}"></nz-alert>
  <button swrAutoFocus nz-button [nzBlock]="true" [nzType]="'primary'" [routerLink]="['/auth/login']">
    {{ 'authentication.recover-password.go-login' | translate:locale.language | uppercase }}
  </button>
</div>

<ng-template #prefixUser><em nz-icon nzType="user"></em></ng-template>
