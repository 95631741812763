import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { SLogService } from 'ngx-papyros';

import { AuthenticationService } from '../../shared/authentication/authentication.service';

/**
 * The Authentication Login component
 */
@Component({
  selector: 'swr-authentication-recover-password',
  templateUrl: './authentication-recover-password.component.html'
})
export class AuthenticationRecoverPasswordComponent implements OnInit {

  /**
   * The email.
   */
  email: string = null;

  /**
   * The Key of the current error
   */
  errorKey: string;

  /**
   * The Key of the success
   */
  successKey: string;

  /**
   * If the form has been submitted
   */
  isProcessing: boolean;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly authenticationService: AuthenticationService,
              private readonly cd: ChangeDetectorRef, private readonly logger: SLogService) {
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.authenticationService.clearSession();
    this.cd.detectChanges(); // Required for ng-zorro because autoFocus directive is being used in the component.
  }

  /**
   * On form submit event
   */
  onSubmitForm(...formModels: NgModel[]): void {
    if (this.email) {
      this.isProcessing = true;

      this.authenticationService.recoverPassword(this.email)
        .then((url: string) => this.handleRecoverPasswordSuccess())
        .catch((error: HttpErrorResponse) => this.handleRecoverPasswordError(error));
    } else {
      formModels.forEach((formModel: NgModel) => formModel.control.markAsDirty());
    }
  }

  /**
   * Detects form change events
   */
  onChange(): void {
    this.errorKey = undefined;
    this.successKey = undefined;
  }

  // HANDLERS

  private handleRecoverPasswordSuccess(): void {
    this.errorKey = undefined;
    this.successKey = 'authentication.recover-password.success';
    this.isProcessing = false;

  }

  private handleRecoverPasswordError(error: HttpErrorResponse = null): void {
    this.successKey = undefined;
    this.errorKey = 'authentication.recover-password.error';
    this.isProcessing = false;
    this.logger.error(AuthenticationRecoverPasswordComponent.name, 'Recover Password error', error);
  }
}
