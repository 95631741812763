import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { audit } from 'rxjs/operators';
import { EMPTY, timer } from 'rxjs';

import { isMobileWidth } from '../../shared/utils/responsivness.util';
import { MenuComponent } from '../menu/menu.component';
import { SseErrorService } from '../../shared/sse-error/sse-error.service';
import { environment } from '../../../environments/environment';

/**
 * Main component.
 */
@Component({
  selector: 'swr-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  /**
   * The menu component.
   */
  @ViewChild(MenuComponent, { static: false }) menu: MenuComponent;

  /**
   * Reload sse event template.
   */
  @ViewChild('reloadSseEventTemplate', { static: true }) reloadSseEventTemplate: TemplateRef<any>;

  /**
   * The mobile state.
   */
  isMobile: boolean;

  /**
   * If has error banner
   */
  hasGeneralSseError: boolean;

  /**
   * Listener for window resize.
   */
  @HostListener('window:resize', [])
  onResize(): void {
    this.isMobile = isMobileWidth();
  }

  constructor(private readonly sseErrorService: SseErrorService) {
    this.subscribeForSseGeneralError();
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.isMobile = isMobileWidth();
  }

  /**
   * handles the menu drawer event.
   */
  handleMenuEvent(open: boolean): void {
    open ? this.menu.open() : this.menu.close();
  }

  private subscribeForSseGeneralError(): void {
    this.sseErrorService.generalErrorSubject
      .pipe(audit((hasError: boolean) => {
        return hasError ? timer(environment.sse.generalErrorDelay) : EMPTY;
      }))
      .subscribe((hasError: boolean) => {
        this.hasGeneralSseError = hasError;
      });
  }
}
