import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { ChartsModule } from 'ng2-charts';

import { ChartThresholdSelectorComponent } from './components/chart-threshold-selector/chart-threshold-selector.component';
import { GeomagneticFieldComponent } from './components/geomagnetic-field/geomagnetic-field.component';
import { OutagesTableComponent } from './components/outages-table/outages-table.component';
import { PercentileComponent } from './components/percentile/percentile.component';
import { HistogramComponent } from './components/histogram/histogram.component';
import { AreaSelectorComponent } from './components/area-selector/area-selector.component';
import { ContinuityRiskInfoComponent } from './components/continuity-risk-info/continuity-risk-info.component';
import { LinearTimeSeriesChartComponent } from './components/linear-time-series-chart/linear-time-series-chart.component';
import { AvailabilityAreaChartComponent } from './components/availability-area-chart/availability-area-chart.component';
import { AvailabilitySectionSelectorComponent } from './components/availability-section-selector/availability-section-selector.component';
import { ChartJsComponent } from './components/chartjs/chart-js.component';
import { SIHistogramsComponent } from './components/si-histograms/si-histograms.component';
import { StanfordDiagramsComponent } from './components/stanford-diagrams/stanford-diagrams.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { NgZorroModule } from './ng-zorro/ng-zorro.module';
import { LinearTimeSeriesBigDataChartComponent } from './components/linear-time-series-for-big-data/linear-time-series-big-data-chart.component';
import { SpaceWeatherAlertsComponent } from './components/space-weather-alerts/space-weather-alerts.component';
import { SpaceWeatherAlertsTableComponent } from './components/space-weather-alerts-table/space-weather-alerts-table.component';
import { UserHasPermissionDirective } from './authentication/user-has-permission.directive';
import { CoordinatesPipe } from './pipes/coordinates.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { AirportSelectorComponent } from './components/airport-selector/airport-selector.component';
import { ServiceSelectorComponent } from './components/service-selector/service-selector.component';
import { StationSelectorComponent } from './components/station-selector/station-selector.component';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { ChartLegendComponent } from './components/chart-legend/chart-legend.component';
import { SeriesSummaryComponent } from './components/series-summary/series-summary.component';
import { DetailedCardsLayoutComponent } from './components/detailed-cards-layout/detailed-cards-layout.component';
import { EnumDataSourceSelectorComponent } from './components/enum-data-source-selector/enum-data-source-selector.component';

/**
 * Application main module.
 */
@NgModule({
  providers: [
    DatePipe
  ],
  imports: [
    ChartsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgZorroModule,
    L10nTranslationModule,
    L10nIntlModule,
  ],
  exports: [
    ChartsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    OutagesTableComponent,
    PercentileComponent,
    HistogramComponent,
    AreaSelectorComponent,
    ContinuityRiskInfoComponent,
    LinearTimeSeriesChartComponent,
    AvailabilityAreaChartComponent,
    ChartJsComponent,
    StanfordDiagramsComponent,
    LinearTimeSeriesBigDataChartComponent,
    GeomagneticFieldComponent,
    SIHistogramsComponent,
    NgZorroModule,
    AutoFocusDirective,
    UserHasPermissionDirective,
    JoinPipe,
    CoordinatesPipe,
    AirportSelectorComponent,
    ServiceSelectorComponent,
    StationSelectorComponent,
    DateRangeSelectorComponent,
    SeriesSummaryComponent,
    DetailedCardsLayoutComponent,
    L10nTranslationModule,
    L10nIntlModule,
    EnumDataSourceSelectorComponent
  ],
  declarations: [
    OutagesTableComponent,
    PercentileComponent,
    HistogramComponent,
    AreaSelectorComponent,
    ContinuityRiskInfoComponent,
    LinearTimeSeriesChartComponent,
    AvailabilityAreaChartComponent,
    AvailabilitySectionSelectorComponent,
    ChartJsComponent,
    SIHistogramsComponent,
    StanfordDiagramsComponent,
    GeomagneticFieldComponent,
    LinearTimeSeriesBigDataChartComponent,
    AutoFocusDirective,
    SpaceWeatherAlertsComponent,
    SpaceWeatherAlertsTableComponent,
    UserHasPermissionDirective,
    JoinPipe,
    CoordinatesPipe,
    AirportSelectorComponent,
    ServiceSelectorComponent,
    StationSelectorComponent,
    DateRangeSelectorComponent,
    ChartLegendComponent,
    SeriesSummaryComponent,
    ChartThresholdSelectorComponent,
    DetailedCardsLayoutComponent,
    EnumDataSourceSelectorComponent,
  ]
})
export class SharedModule {
  /**
   * Provider export method.
   */
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
