import { en_GB, pt_PT } from 'ng-zorro-antd/i18n';
import { enGB, pt } from 'date-fns/esm/locale';
import { L10nConfig } from 'angular-l10n';

import { LocaleModel } from './locale.model';

/**
 * The locales data.
 */
export const availableLocales: LocaleModel[] = [
  {
    id: 'en_GB',
    name: 'English (UK)',
    ngZorro: en_GB,
    dateFns: enGB,
    l10nLocale: { language: 'en-GB', timeZone: 'Europe/London' }
  },
  {
    id: 'pt_PT',
    name: 'Português (PT)',
    ngZorro: pt_PT,
    dateFns: pt,
    l10nLocale: { language: 'pt-PT', timeZone: 'Europe/Lisbon' }
  }
];

/**
 * The default locale.
 */
export const defaultLocale: LocaleModel = availableLocales[0];

/**
 * Localization configurations
 */
export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [
    { name: 'l10n', asset: './assets/l10n/locale' }
  ],
  cache: true,
  keySeparator: '.',
  defaultLocale: defaultLocale.l10nLocale,
  schema: [
    { locale: availableLocales[0].l10nLocale, dir: 'ltr' },
    { locale: availableLocales[1].l10nLocale, dir: 'ltr' }
  ]
};
