<div class="nav-bar-container">
  <div *ngIf="hasGeneralSseError" class="error-box error-banner">
    <em class="error-icon" nz-icon nzTheme="outline" nzType="close-circle"></em>
    <div class="error-msg">{{ 'sse.error.general' | translate:locale.language }}</div>
  </div>
  <div class="nav-bar">
    <div class="left-container">
      <button (click)="openMenu()" class="nav-button" nz-button>
        <em nz-icon nzTheme="outline" nzType="menu-unfold"></em>
      </button>
      <h1 [title]="sectionKey | translate:locale.language"
          *ngIf="sectionKey">{{ sectionKey | translate:locale.language }}</h1>
    </div>
    <div class="right-container">
      <div [class.active]="sectionKey === 'notifications.title'" [class.has-notifications]="hasNotifications"
           class="nav-button notification-container">
        <ng-container *ngIf="!isLoadingNotifications else loadingIcon">
          <em [nzDropdownMenu]="notifications" [nzTheme]="hasNotifications ? 'fill' : 'outline'"
              nz-dropdown nz-icon nzTrigger="click" nzType="bell"></em>
        </ng-container>
      </div>
      <div [nzDropdownMenu]="menu" class="user" nz-dropdown nzTrigger="click">
        <div class="user-image text-center">
          <em nz-icon nzTheme="outline" nzType="user"></em>
        </div>
        <div class="user-name">{{ userName }}</div>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li [routerLink]="['/edit-profile']" nz-menu-item>{{ 'nav.profile.label' | translate:locale.language }}</li>
          <li (click)="logout()" nz-menu-item>{{ 'nav.logout.label' | translate:locale.language }}</li>
        </ul>
      </nz-dropdown-menu>
      <svg class="skew-container" preserveAspectRatio="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <polygon class="skew" points="100 100, 100 0, 65 0, 0 100"></polygon>
        <polygon class="highlight-skew" points="65 0, 35 0, 0 100"></polygon>
      </svg>
      <div class="date-time-container">
        <div class="time-container">
          <span class="time">{{ today | l10nDate:locale.language:{ timeStyle: 'short', timeZone: 'UTC' }:'UTC' }}</span>
          {{ 'general.utc' | translate:locale.language }}
        </div>
        <div class="date">{{ today | l10nDate:locale.language:{ dateStyle: 'full', timeZone: 'UTC' }:'UTC' }}</div>
      </div>
    </div>
  </div>
</div>

<nz-dropdown-menu #notifications="nzDropdownMenu">
  <ul nz-menu>
    <li *ngFor="let airport of unreadByAirport" [queryParams]="{'airport': airport.id}"
        [routerLink]="['/notifications']" class="notification-item" nz-menu-item>
      <span class="pr-md">{{ airport.name }}</span>
      <nz-badge [nzStandalone]="true" *ngIf="!hasNotificationError" [nzCount]="airport.unread"
                [nzShowZero]="false"></nz-badge>
      <nz-badge [nzStandalone]="true" *ngIf="hasNotificationError" [nzCount]="errorTemplate"></nz-badge>
    </li>
  </ul>
</nz-dropdown-menu>

<ng-template #loadingIcon>
  <em nz-icon nzType="loading"></em>
</ng-template>

<ng-template #errorTemplate>
  <span class="notification-error">
    <em nz-icon nzType="exclamation"></em>
  </span>
</ng-template>
