import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthenticationService } from '../../shared/authentication/authentication.service';

/**
 * Menu component.
 */
@Component({
  selector: 'swr-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit, OnDestroy {

  /**
   * The window width state.
   */
  @Input()
  set mobileState(isMobile: boolean) {
    this.cd.detectChanges();
    this.isMobile = isMobile;
    this.visible = !this.isMobile;
  }

  /**
   * The mobile state.
   */
  isMobile: boolean;

  /**
   * The visibility state.
   */
  visible: boolean;

  private readonly subscriptions: Subscription[] = [];

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly authenticationService: AuthenticationService,
              private readonly cd: ChangeDetectorRef, private readonly router: Router) {
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((event: RouterEvent) => (event instanceof NavigationEnd) && this.isMobile))
        .subscribe(() => this.close())
    );
  }

  /**
   * Handles destruction tasks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Open the drawer.
   */
  open(): void {
    this.visible = true;
  }

  /**
   * Close the drawer.
   */
  close(): void {
    this.visible = false;
  }

  /**
   * User has at least one permission
   */
  hasPermissions(permissions: string[]): boolean {
    return this.authenticationService.hasPermissions(permissions);
  }

  /**
   * Close all opened overlay backdrops.
   */
  closeOverlayOpened(): void {
    document.querySelectorAll('.cdk-overlay-backdrop-showing')
      .forEach((element: HTMLElement) => element.click());
  }
}
