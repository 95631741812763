<form *ngIf="!successKey" nz-form (ngSubmit)="onSubmitForm(password, confirmPassword)" #setPasswordForm="ngForm">
  <nz-alert *ngIf="errorKey" class="mb-md" nzType="error" nzMessage="{{ errorKey | translate:locale.language:{value: minPasswordLength} }}"></nz-alert>
  <!--PASSWORD-->
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ newPassword ? '': 'authentication.set-password.password.empty' | translate:locale.language }}"
                     [nzValidateStatus]="checkInputStatus(setPasswordForm, password, newPassword)">
      <nz-input-group [nzPrefix]="prefixKey">
        <input swrAutoFocus required nz-input type="password" name="password" [(ngModel)]="newPassword" #password="ngModel"
               [minLength]="minPasswordLength" (keydown)="onChange()"
               placeholder="{{ 'authentication.set-password.password.label' | translate:locale.language }}" autocomplete="new-password"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <!--PASSWORD CONFIRMATION-->
  <nz-form-item>
    <nz-form-control nzErrorTip="{{ newPasswordConfirmation ? '': 'authentication.set-password.confirm-password.empty' | translate:locale.language }}"
                     [nzValidateStatus]="checkInputStatus(setPasswordForm, confirmPassword, newPasswordConfirmation)">
      <nz-input-group [nzPrefix]="prefixKey">
        <input required nz-input type="password" name="confirm-password" [(ngModel)]="newPasswordConfirmation"
               [minLength]="minPasswordLength" #confirmPassword="ngModel" (keydown)="onChange()"
               placeholder="{{ 'authentication.set-password.confirm-password.label' | translate:locale.language }}" autocomplete="new-password"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <!--SUBMIT-->
  <nz-form-control>
    <button nz-button [nzBlock]="true" [nzType]="'primary'" [nzLoading]="isProcessing" type="submit">
      {{ 'authentication.set-password.button' | translate:locale.language | uppercase }}
    </button>
  </nz-form-control>
</form>
<!--LOGIN-->
<div *ngIf="!successKey" class="mt-md text-center">
  <a class="forgot-password-link" [routerLink]="['/auth/login']">
    <em nz-icon nzType="lock"></em>
    {{ 'authentication.recover-password.login' | translate:locale.language }}
  </a>
</div>

<div *ngIf="successKey">
  <nz-alert *ngIf="successKey" class="mb-lg" nzType="success" nzMessage="{{ successKey | translate:locale.language }}"></nz-alert>
  <button swrAutoFocus nz-button [nzBlock]="true" [nzType]="'primary'" [routerLink]="['/auth/login']">
    {{ 'authentication.set-password.go-login' | translate:locale.language | uppercase }}
  </button>
</div>

<ng-template #prefixKey><em nz-icon nzType="key"></em></ng-template>
