import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MainComponent } from './main/main.component';

import { MenuComponent } from './menu/menu.component';
import { SharedModule } from '../shared/shared.module';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { NavComponent } from './nav/nav.component';

/**
 * Application core module.
 */
@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    MenuComponent,
    NavComponent
  ],
  declarations: [
    MainComponent,
    MenuComponent,
    NavComponent
  ]
})
export class CoreModule {

  /**
   * Constructor for the CoreModule.
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
