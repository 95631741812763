import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationComponent } from './authentication.component';
import { AuthenticationLoginComponent } from './login/authentication-login.component';
import { AuthenticationRecoverPasswordComponent } from './recover-password/authentication-recover-password.component';
import { AuthenticationSetPasswordComponent } from './set-password/authentication-set-password.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationLoginComponent
      },
      {
        path: 'recover-password',
        component: AuthenticationRecoverPasswordComponent
      },
      {
        path: 'set-password/:token',
        component: AuthenticationSetPasswordComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      }
    ]
  }
];

/**
 * Authentication routing module
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}
