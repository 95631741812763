import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SLogService } from 'ngx-papyros';

import { sessionExpiredErrorCodes } from '../utils/status-code.enum';
import { AuthenticationService } from './authentication.service';

// Interceptor Constants
const CONTENT_TYPE: string = 'Content-Type';
const ACCEPT: string = 'Accept';
const AUTHORIZATION: string = 'Authorization';
const APPLICATION_JSON_MIME_TYPE: string = 'application/json';

type StringFunction = (value: string) => string;
type HasHeaderFunction = (request: HttpRequest<any>, header: string) => boolean;
type HasBodyFunction = (request: HttpRequest<any>) => boolean;

const bearer: StringFunction = (value: string): string => {
  return `Bearer ${value}`;
};

const hasHeader: HasHeaderFunction = (request: HttpRequest<any>, header: string): boolean => {
  return request && request.headers && request.headers.has(header);
};

const hasBody: HasBodyFunction = (request: HttpRequest<any>): boolean => {
  return request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH';
};

/**
 * Interceptor for token authentication.
 */
export class TokenInterceptor implements HttpInterceptor {

  // tslint:disable:variable-name
  private isTokenInvalid: boolean = false;
  private _authenticationService: AuthenticationService;
  private _router: Router;
  private _logger: SLogService;

  // tslint:enable:variable-name

  /**
   * Gets the cached authentication service.
   */
  get authenticationService(): AuthenticationService {
    if (!this._authenticationService) {
      this._authenticationService = this.injector.get(AuthenticationService);
    }
    return this._authenticationService;
  }

  /**
   * Gets the cached router.
   */
  get router(): Router {
    if (!this._router) {
      this._router = this.injector.get(Router);
    }
    return this._router;
  }

  /**
   * Gets the cached router.
   */
  get logger(): SLogService {
    if (!this._logger) {
      this._logger = this.injector.get(SLogService);
    }
    return this._logger;
  }

  constructor(private readonly injector: Injector) {
  }

  /**
   * Intercepts all HTTP requests and injects the access token. When a 401 HTTP code is detected then it will try to
   * refresh the access token and retry the request. If the authentication it's not possible it will send the user to
   * the login page.
   * @param request the request being executed.
   * @param next the HTTP handler to chain the request.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const nextRequest: HttpRequest<any> = this.setHeaders(request);
    return next.handle(nextRequest)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (!this.isTokenInvalid && sessionExpiredErrorCodes.indexOf(error.status) !== -1
              && error.url.indexOf('authentication') === -1) {
              this.handleSessionError();
            }
          }
          return throwError(error);
        })
      );
  }

  private setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const headers: any = {};

    const accessToken: string = this.authenticationService.getToken();
    let mutate: boolean = false;

    if (!hasHeader(request, CONTENT_TYPE) && hasBody(request) && !(request.body instanceof FormData)) {
      headers[CONTENT_TYPE] = APPLICATION_JSON_MIME_TYPE;
      mutate = true;
    }
    if (!hasHeader(request, ACCEPT)) {
      headers[ACCEPT] = APPLICATION_JSON_MIME_TYPE;
      mutate = true;
    }

    // Does not add the authorization header if we don't have access token and if the request is not to assets.
    // Browser responds 400 if we request assets with a large authorization header.
    if (accessToken && request.url.indexOf('/assets/') < 0) {
      headers[AUTHORIZATION] = bearer(accessToken);
      mutate = true;
    }

    return mutate ? request.clone({ setHeaders: headers }) : request;
  }

  private handleSessionError(): void {
    this.isTokenInvalid = true;
    this.authenticationService.clearSession();
    this.router.navigate(['auth', 'login'])
      .then(() => this.isTokenInvalid = false)
      .catch((error: Error) => {
        this.logger.error(TokenInterceptor.name, 'Navigation error', error);
      });
  }
}
