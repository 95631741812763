import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Directive to perform autofocus on input.
 */
@Directive({
  selector: '[swrAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(private readonly el: ElementRef) {
  }

  /**
   * Handles after initialization tasks.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 50);
  }
}
