import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { PermissionsManager } from '@pmds/js-permissions-sdk';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TokenResponseModel } from './model/token-response.model';
import { LoginModel } from './model/login.model';
import { session, SessionModel } from './model/session.model';

const STORAGE_TOKEN_KEY: string = 'token';
const STORAGE_AIRPORT_KEY: string = 'airport';

/**
 * Authentication service.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Session changed event.
   */
  sessionChanged: Subject<SessionModel> = new Subject<SessionModel>();

  private session: SessionModel;
  private redirectUrl: string;
  private permissionsManager: PermissionsManager;
  private readonly storage: Storage = localStorage;
  private readonly jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private readonly httpClient: HttpClient) {

  }

  /**
   * Login the user.
   * @param userLogin The user login data.
   */
  login(userLogin: LoginModel): Promise<string> {
    return new Promise((resolve: (value?: string) => void, reject: (reason?: HttpErrorResponse) => void): void => {
      this.httpClient.post(`${environment.apiPath}/authentication`, { email: userLogin.username, password: userLogin.password })
        .subscribe(
          (loginResponse: TokenResponseModel) => {
            try {
              this.createSession(loginResponse.token);
              resolve(this.redirectUrl);
            } catch (error) {
              reject(error);
            }
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Recover password action.
   * @param userName The user name.
   */
  recoverPassword(userName: string): Promise<string> {
    return new Promise((resolve: (value?: string) => void, reject: (reason?: HttpErrorResponse) => void): void => {
      this.httpClient.post(`${environment.apiPath}/authentication/recover-password`, { email: userName })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Recover password action.
   * @param password The user password.
   * @param token The user token.
   */
  setPassword(password: string, token: string): Promise<string> {
    return new Promise((resolve: (value?: string) => void, reject: (reason?: HttpErrorResponse) => void): void => {
      this.httpClient.post(`${environment.apiPath}/authentication/change-temporary-password`, { token, password })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Recover password action.
   * @param currentPassword The user password.
   * @param newPassword The user token.
   */
  changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (reason?: HttpErrorResponse) => void): void => {
      this.httpClient.post(`${environment.apiPath}/authentication/change-password`, { currentPassword, newPassword })
        .subscribe(
          (response: TokenResponseModel) => {
            try {
              this.createSession(response.token);
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  // TODO: change mock logout
  /**
   * Logout the user.
   */
  logout(): Promise<void> {
    return new Promise((resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
      this.clearSession();
      resolve();
      // reject({ status: 403 } as HttpErrorResponse);
    });
  }

  /**
   * set the user name.
   */
  setUserName(name: string): void {
    if (this.checkSession()) {
      this.session.name = name;

      this.sessionChanged.next(this.session);
    }
  }

  /**
   * Gets the user name.
   */
  getUserName(): string {
    return (this.session && this.session.name) || null;
  }

  /**
   * Gets the user name.
   */
  getAirport(): string {
    return (this.session && this.session.defaultAirportId) || null;
  }

  /**
   * Gets the user locale.
   */
  getLocale(): string | null {
    return this.session?.locale ?? null;
  }

  /**
   * Gets the session token.
   */
  getToken(): string {
    return (this.session && this.session.jwtToken) || null;
  }

  /**
   * Checks if session is valid.
   */
  checkSession(): boolean {
    const storedToken: string = this.storage.getItem(STORAGE_TOKEN_KEY);

    if (storedToken && !this.session) {
      try {
        this.createSession(storedToken);
      } catch (error) {
        return false;
      }
    }

    return this.session ? this.session.jwtToken !== null : false;
  }

  /**
   * Sets the redirect url.
   */
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  /**
   * Clears the session data.
   */
  clearSession(): void {
    this.storage.removeItem(STORAGE_TOKEN_KEY);
    this.storage.removeItem(STORAGE_AIRPORT_KEY);
    this.redirectUrl = null;
    this.session = null;
  }

  /**
   * Checks if user has the required permission.
   * @param requiredPermissions The required permissions.
   */
  hasPermissions(requiredPermissions: string[]): boolean {
    return this.permissionsManager.hasOneValidPermission(requiredPermissions);
  }

  /**
   * Create new session from token.
   */
  createSession(token: string): void {
    if (!token) {
      throw new Error('Token does not exists');
    }

    const parsedToken: any = this.parseToken(token);
    this.storage.setItem(STORAGE_TOKEN_KEY, token);

    this.session = session(
      parsedToken.sub,
      parsedToken.name,
      parsedToken.defaultAirportId,
      token,
      parsedToken.permissions,
      parsedToken.locale
    );

    this.permissionsManager = new PermissionsManager(parsedToken.permissions);
  }

  private parseToken(token: string): any {
    const parts: any[] = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded: any = this.jwtHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }
}
