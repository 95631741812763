/**
 * RGB Color
 */
export class RgbColor {
  /**
   * Red value
   */
  red: number;

  /**
   * Green value
   */
  green: number;

  /**
   * Blue value
   */
  blue: number;

  constructor(red: number, green: number, blue: number) {
    this.red = red;
    this.green = green;
    this.blue = blue;
  }

  /**
   * Returns color in string format
   */
  toString(): string {
    return `rgb(${this.red}, ${this.green}, ${this.blue})`;
  }
}

/**
 * RGBA Color
 */
export class RgbaColor extends RgbColor {
  /**
   * Alpha value
   */
  alpha: number;

  constructor(color: RgbColor, alpha: number) {
    super(color.red, color.green, color.blue);

    this.alpha = alpha;
  }

  /**
   * Returns color in string format
   */
  toString(): string {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`;
  }
}
