import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { SLogService } from 'ngx-papyros';

import { AuthenticationService } from '../../shared/authentication/authentication.service';

/**
 * The Authentication Login component
 */
@Component({
  selector: 'swr-authentication-set-password',
  templateUrl: './authentication-set-password.component.html'
})
export class AuthenticationSetPasswordComponent implements OnInit {

  /**
   * The password
   */
  newPassword: string = null;

  /**
   * The confirm password password
   */
  newPasswordConfirmation: string = null;

  /**
   * The Key of the current error
   */
  errorKey: string;

  /**
   * The Key of the success
   */
  successKey: string;

  /**
   * If the form has been submitted
   */
  isProcessing: boolean;

  /**
   * The minimum password length.
   */
  readonly minPasswordLength: number = 8;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly authenticationService: AuthenticationService,
              private readonly route: ActivatedRoute, private readonly cd: ChangeDetectorRef, private readonly logger: SLogService) {
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.authenticationService.clearSession();
    this.cd.detectChanges(); // Required for ng-zorro because autoFocus directive is being used in the component.
  }

  /**
   * On form submit event
   */
  onSubmitForm(...formModels: NgModel[]): void {
    if (!this.newPassword || !this.newPasswordConfirmation) {
      formModels.forEach((formModel: NgModel) => formModel.control.markAsDirty());
      return;
    } else if (this.newPassword.length < this.minPasswordLength || this.newPasswordConfirmation.length < this.minPasswordLength) {
      this.errorKey = 'authentication.set-password.error.length';
      return;
    } else if (this.newPassword !== this.newPasswordConfirmation) {
      this.errorKey = 'authentication.set-password.error.match';
      return;
    }

    this.authenticationService.setPassword(this.newPassword, this.route.snapshot.params.token)
      .then(() => this.handleSetPasswordSuccess())
      .catch((error: HttpErrorResponse) => this.handleSetPasswordError(error));
  }

  /**
   * Check the input error status
   */
  checkInputStatus(form: NgForm, input: NgModel, value: string): string {
    if (input.dirty && !value) {
      return 'error';
    } else if (form.submitted && this.errorKey) {
      if (this.errorKey === 'authentication.set-password.error.length') {
        return value.length >= this.minPasswordLength ? '' : 'error';
      } else {
        return 'error';
      }
    } else {
      return '';
    }
  }

  /**
   * Detects form change events
   */
  onChange(): void {
    this.errorKey = undefined;
    this.successKey = undefined;
  }

  // HANDLERS

  private handleSetPasswordSuccess(): void {
    this.errorKey = undefined;
    this.successKey = 'authentication.set-password.success';
    this.isProcessing = false;
  }

  private handleSetPasswordError(error: HttpErrorResponse = null): void {
    this.successKey = undefined;
    this.errorKey = 'authentication.set-password.error.request';
    this.isProcessing = false;
    this.logger.error(AuthenticationSetPasswordComponent.name, 'Set Password error', error);
  }
}
