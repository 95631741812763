/**
 * Notification action Model.
 */
export interface NotificationActionModel {
  /**
   * The unread state
   */
  unread: boolean;

  /**
   * The notification ids
   */
  notificationIds: string[];
}

/**
 * Creates an notification action.
 */
export const notificationAction: (unread: boolean, notificationIds: string[]) =>
  NotificationActionModel = (unread: boolean, notificationIds: string[]): NotificationActionModel => {
  return {
    unread,
    notificationIds
  };
};
