import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { L10nIntlModule, L10nLoader, L10nTranslationModule } from 'angular-l10n';
import { PapyrosModule } from 'ngx-papyros';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { CoreModule } from './core/core.module';
import { TokenInterceptor } from './shared/authentication/token.interceptor';
import { initL10n } from './shared/localization/localization.service';
import { HttpTranslationLoader } from './shared/localization/translation-loader';
import { NgZorroModule } from './shared/ng-zorro/ng-zorro.module';
import { environment } from '../environments/environment';
import { l10nConfig } from './shared/localization/localization.config';

/**
 * Application module.
 */
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    AppRoutingModule,
    CoreModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
        translationLoader: HttpTranslationLoader
      }),
    L10nIntlModule,
    PapyrosModule.forRoot(environment.loggerConfig),
    NgZorroModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      deps: [Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
