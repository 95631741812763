/**
 * Checks if window has tablet or mobile width (less than 992px).
 */
export function isMobileWidth(): boolean {
  return !window.matchMedia('(min-width: 992px)').matches;
}

/**
 * Checks if window width is less than 768px.
 */
export function isSmallScreenWidth(): boolean {
  return !window.matchMedia('(min-width: 768px)').matches;
}
