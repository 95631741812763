import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';

import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { SLogService } from 'ngx-papyros';

import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { LoginModel } from '../../shared/authentication/model/login.model';
import { LocalizationService } from '../../shared/localization/localization.service';

/**
 * The Authentication Login component
 */
@Component({
  selector: 'swr-authentication-login',
  templateUrl: './authentication-login.component.html',
  styleUrls: ['./authentication-login.component.less']
})
export class AuthenticationLoginComponent implements OnInit {

  /**
   * The user data model
   */
  user: LoginModel = { username: null, password: null };

  /**
   * The Key of the current error
   */
  errorKey: string;

  /**
   * If the form has been submitted
   */
  isProcessingLogin: boolean;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private readonly authenticationService: AuthenticationService,
              private readonly router: Router, private readonly cd: ChangeDetectorRef, private readonly logger: SLogService,
              private readonly localization: LocalizationService) {
  }

  /**
   * Handles initialization tasks.
   */
  ngOnInit(): void {
    this.authenticationService.clearSession();
    this.cd.detectChanges(); // Required for ng-zorro because autoFocus directive is being used in the component.
  }

  /**
   * On form submit event
   */
  onSubmitForm(...formModels: NgModel[]): void {
    if (this.user.username && this.user.password) {
      this.isProcessingLogin = true;

      this.authenticationService.login(this.user)
        .then((url: string) => this.handleLoginSuccess(url))
        .catch((error: HttpErrorResponse) => this.handleLoginError(error));
    } else {
      formModels.forEach((formModel: NgModel) => formModel.control.markAsDirty());
    }
  }

  /**
   * Detects form change events
   */
  onChange(): void {
    this.errorKey = undefined;
  }

  // HANDLERS

  private handleLoginSuccess(url: string): void {
    let landingPage: string;

    if (url) {
      landingPage = url.indexOf('?') > -1 ? url.substring(0, url.indexOf('?')) : url;
    } else {
      if (this.authenticationService.hasPermissions(['pa.gnss', 'pa.sw'])) {
        landingPage = 'performance-assessment';
      } else if (this.authenticationService.hasPermissions(['om.gnss', 'om.sw'])) {
        landingPage = 'operational-monitoring';
      } else if (this.authenticationService.hasPermissions(['forecast'])) {
        landingPage = 'forecast';
      } else if (this.authenticationService.hasPermissions(['airport'])) {
        landingPage = 'airport';
      } else if (this.authenticationService.hasPermissions(['admin'])) {
        landingPage = 'admin';
      } else {
        landingPage = 'error/403';
      }
    }

    this.localization.tryToSwitchLocale(this.authenticationService.getLocale())
      .catch((error: Error) => {
        this.logger.error(AuthenticationLoginComponent.name, 'tryToSwitchLocale error', error);
      });

    this.router.navigate([landingPage], { replaceUrl: true })
      .catch((error: Error) => {
        this.logger.error(AuthenticationLoginComponent.name, 'Navigation error', error);
      });
  }

  private handleLoginError(error: HttpErrorResponse = null): void {
    this.errorKey = 'authentication.login.error.invalid-credentials';
    this.isProcessingLogin = false;
    this.logger.error(AuthenticationLoginComponent.name, 'Login error', error);
  }
}
