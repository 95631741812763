import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { Subject } from 'rxjs';

import { LocalizationService } from '../localization/localization.service';
import { TitlePathTranslationModel } from './model/title-path-translation.model';
import { createDefaultKey, createPathTranslationKey, createSuffixKey, SUFFIX_JOINER, TITLES_JOINER } from './utils/html-title.utils';
import { PageInfoModel } from './model/page-info-model';

/**
 * The html head title resolver service.
 */
@Injectable({
  providedIn: 'root'
})
export class HtmlTitleService {

  /**
   * The title subject.
   */
  titleSubject: Subject<PageInfoModel> = new Subject();

  private isInitialized: boolean;

  constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly titleService: Title,
              private readonly localization: LocalizationService) {
    this.isInitialized = false;
  }

  /**
   * Initiates the service.
   */
  init(): void {
    if (!this.isInitialized) {
      this.subscribeToRouteChanges();
      this.updateHtmlTitle();
      this.isInitialized = true;
    }
  }

  private updateHtmlTitle(): void {
    const url: string = this.router.url.split('?')[0];
    const paths: string[] = url.split('/');

    let translations: TitlePathTranslationModel[] = [];
    let translationIndex: number = 0;

    for (const path of paths) {
      if (!path) {
        continue;
      }

      let pathKey: string;

      if (!translationIndex) {
        pathKey = path;
      } else {
        pathKey = `${translations[translationIndex - 1].pathKey}.${path}`;
      }
      const pathTranslationKey: string = createPathTranslationKey((pathKey));
      let translation: string = this.localization.translate(pathTranslationKey);

      if (translation === pathTranslationKey || typeof translation !== 'string') {
        translation = null;
      }

      translations.push({ pathKey, pathValue: translation });
      ++translationIndex;
    }

    translations = translations.filter((translation: TitlePathTranslationModel) => translation.pathValue !== null);

    if (!translations.length) {
      this.titleService.setTitle(this.localization.translate(createDefaultKey()));
      this.titleSubject.next({ title: this.titleService.getTitle(), path: this.router.url });
      return;
    }

    const titleSuffix: string = this.localization.translate(createSuffixKey());
    this.titleService.setTitle(`${translations.map((translation: TitlePathTranslationModel) =>
      translation.pathValue).join(TITLES_JOINER)}${SUFFIX_JOINER}${titleSuffix}`);
    this.titleSubject.next({ title: this.titleService.getTitle(), path: this.router.url });
  }

  private subscribeToRouteChanges(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.updateHtmlTitle();
      }
    });
  }
}
