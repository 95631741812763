<nz-drawer (nzOnClose)="close()" [nzClosable]="false" [nzKeyboard]="isMobile" [nzMask]="isMobile" [nzPlacement]="'left'"
           [nzVisible]="visible" [nzWidth]="272" [nzZIndex]="10000" [nzWrapClassName]="'menu'"
           [nzCloseOnNavigation]="false">
  <ng-template nzDrawerContent>
    <div (click)="closeOverlayOpened()">
      <div class="logo-container no-select">
        <img alt="SWAIR logo" src="assets/images/swair-logo-wh.svg">
      </div>
      <ul>
        <li *swrUserHasPermission="['om.gnss', 'om.sw']">
          <a (click)="isMobile ? close() :  ''"
             routerLinkActive="active disable-pointer-events"
             [routerLink]="['/operational-monitoring']">
            <em nz-icon nzTheme="outline" nzType="warning"></em>
            <span>{{ 'menu.operational-monitoring' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['om.atc']">
          <a (click)="isMobile ? close() : ''" [class.sub-menu]="hasPermissions(['om.gnss', 'om.sw'])"
             routerLinkActive="active disable-pointer-events" [routerLink]="['/atc']">
          <span class="icon">
            <img alt="ATC icon" src="assets/images/atc-view-icon.svg">
          </span>
            <span>{{ 'menu.atc-view' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['om.ads-b']">
          <a (click)="isMobile ? close() : ''" [class.sub-menu]="hasPermissions(['om.gnss', 'om.sw'])"
             [routerLinkActive]="'active'" [routerLink]="['/adsb']">
            <em nz-icon nzType="global" nzTheme="outline"></em>
            <span>{{ 'menu.ads-b' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['pa.gnss', 'pa.sw']">
          <a (click)="isMobile ? close() : ''" routerLinkActive="active disable-pointer-events"
             [routerLink]="['/performance-assessment']">
            <em nz-icon nzTheme="outline" nzType="line-chart"></em>
            <span>{{ 'menu.performance-assessment' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['forecast']">
          <a (click)="isMobile ? close() : ''" routerLinkActive="active disable-pointer-events"
             [routerLink]="['/forecast']">
            <em nz-icon nzTheme="outline" nzType="calendar"></em>
            <span>{{ 'menu.forecast' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['airport']">
          <a (click)="isMobile ? close() : ''" routerLinkActive="active disable-pointer-events"
             [routerLink]="['/airport']">
          <span class="icon">
            <img alt="Plane icon" src="assets/images/plane-icon.svg">
          </span>
            <span>{{ 'menu.airport' | translate:locale.language }}</span>
          </a>
        </li>
        <li *swrUserHasPermission="['admin']" nz-menu-group
            [nzTitle]="'menu.admin' | translate:locale.language | uppercase">
          <ul>
            <li>
              <a (click)="isMobile ? close() : ''" routerLinkActive="active disable-pointer-events"
                 [routerLink]="['/admin/auditing']">
                <em nz-icon nzTheme="outline" nzType="file-search"></em>
                <span>{{ 'menu.auditing' | translate:locale.language }}</span>
              </a>
            </li>
            <li>
              <a (click)="isMobile ? close() : ''" routerLinkActive="active disable-pointer-events"
                 [routerLink]="['/admin/system-failures']">
                <em nz-icon nzTheme="outline" nzType="exception"></em>
                <span>{{ 'menu.system-failures' | translate:locale.language }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </ng-template>
</nz-drawer>
