import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject, Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { notificationAction } from './model/notification-action.model';
import { NotificationItemModel } from './model/notification-item.model';
import { NotificationQueryParamsModel } from './model/notification-query-params.model';
import { PaginatedListModel } from '../tables/model/paginated-list.model';
import { UnreadNotificationModel } from './model/unread-notification-model';

/**
 * Notification Service
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  /**
   * Returns the observable responsible to emit events when notifications need to be updated due to changes,
   */
  get updateNotificationsObservable(): Observable<void> {
    return this.updateNotificationsSubject.asObservable();
  }

  private getNotificationsSubscription: Subscription;
  private getUnreadSubscription: Subscription;
  private readonly updateNotificationsSubject: Subject<void> = new Subject();

  constructor(private readonly httpClient: HttpClient) {
  }

  /**
   * Get Notifications.
   */
  getNotifications(queryParams: NotificationQueryParamsModel): Promise<PaginatedListModel<NotificationItemModel>> {
    if (this.getNotificationsSubscription) {
      this.getNotificationsSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: PaginatedListModel<NotificationItemModel>) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getNotificationsSubscription = this.httpClient.get<PaginatedListModel<NotificationItemModel>>(
          `${environment.apiPath}/notifications`, { params: this.buildRequestQueryParameters(queryParams) })
          .subscribe(
            (data: PaginatedListModel<NotificationItemModel>) => resolve(data),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Get unread notification number.
   */
  getUnread(): Promise<UnreadNotificationModel> {
    if (this.getUnreadSubscription) {
      this.getUnreadSubscription.unsubscribe();
    }

    return new Promise(
      (resolve: (value?: UnreadNotificationModel) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.getUnreadSubscription = this.httpClient.get<UnreadNotificationModel>(
          `${environment.apiPath}/notifications/unread`)
          .subscribe(
            (data: UnreadNotificationModel) => resolve(data),
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Marks a group of notifications as read.
   * @param notifications - the array of notification ids.
   */
  markNotificationsAsRead(notifications: string[]): Promise<void> {
    return new Promise(
      (resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.httpClient.post(`${environment.apiPath}/notifications/mark`, notificationAction(false, notifications))
          .subscribe(
            () => {
              this.updateNotificationsSubject.next();
              resolve();
            },
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Marks a group of notifications as unread.
   * @param notifications - the array of notification ids.
   */
  markNotificationsAsUnread(notifications: string[]): Promise<void> {
    return new Promise(
      (resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.httpClient.post(`${environment.apiPath}/notifications/mark`, notificationAction(true, notifications))
          .subscribe(
            () => {
              this.updateNotificationsSubject.next();
              resolve();
            },
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  /**
   * Deletes a group of notifications.
   * @param notifications - the array of notification ids.
   */
  deleteNotifications(notifications: string[]): Promise<void> {
    return new Promise(
      (resolve: (value?: void) => void, reject: (reason?: HttpErrorResponse) => void): void => {
        this.httpClient.delete(
          `${environment.apiPath}/notifications/delete`,
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: notificationAction(null, notifications) } as any)
          .subscribe(
            () => {
              this.updateNotificationsSubject.next();
              resolve();
            },
            (error: HttpErrorResponse) => reject(error)
          );
      });
  }

  private buildRequestQueryParameters(queryParams: NotificationQueryParamsModel): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    if (queryParams.limit) {
      httpParams = httpParams.append('limit', queryParams.limit.toString());
    }

    if (queryParams.pageId) {
      httpParams = httpParams.append('pageId', queryParams.pageId.toString());
    }

    if (queryParams.airport) {
      httpParams = httpParams.append('airportId', queryParams.airport);
    }

    if (queryParams.stations) {
      if (Array.isArray(queryParams.stations)) {
        queryParams.stations.forEach((station: string) => {
          httpParams = httpParams.append('stationId', station);
        });
      } else {
        for (const station of queryParams.stations.split(';')) {
          httpParams = httpParams.append('stationId', station);
        }
      }
    }

    if (queryParams.category && queryParams.category !== 'ALL') {
      httpParams = httpParams.append('category', queryParams.category);
    }

    if (queryParams.periodStart && queryParams.periodEnd) {
      httpParams = httpParams
        .append('periodStart', queryParams.periodStart)
        .append('periodEnd', queryParams.periodEnd);
    }

    return httpParams;
  }
}
